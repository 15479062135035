// react
import React, { useState, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'

// Contexts
import AuthContext from '../../../utils/authProvider'

// ant design
import { Card, Button, Tag, Tooltip, Row, Col, Input, Table } from 'antd'

// operations
import { QUERY_DISTRICT_FORMS } from '../../../operations/queries/districts'
import { useQuery } from '@apollo/client'

// components
import DistrictFormSearchForm from './DistrictFormSearchForm'
import Listing from '../../Listing'

const getDistrictName = record =>
  record.district?.name ? record.district.name : record.districtId

const DistrictFormList = () => {
  const Auth = useContext(AuthContext)
  const isSuperAdmin = Auth.hasRole('SUPER_ADMIN')
  const [criterias, setCriterias] = useState({})
  const canAddDistrictForms = Auth.hasPermission('ADD_DISTRICT_FORMS')
  const [value, setValue] = useState('');
  const [pagination, setPagination] = useState({ offset: 0, limit: 10 });
  const { data, loading } = useQuery(QUERY_DISTRICT_FORMS)

  const [dataSource, setDataSource] = useState(data?.districtForms);
  useEffect(async () => {
    setDataSource(data?.districtForms)
  }, [data]);
  const reset = () => {
    setDataSource(data?.districtForms)
    setValue('')
  }
  const extra = (
    <>
      {canAddDistrictForms && (
        <Tooltip title='Add new districtform'>
          <Link to='./districtform/add'>
            <Button type='primary'>Add form</Button>
          </Link>
        </Tooltip>
      )}
    </>
  )

  const onSearch = criterias => {
    setCriterias(criterias)
  }
  let setDistricts = new Set()
  let districtFilter = []
  const districtNames = data?.districtForms?.map(x =>
    setDistricts.add(x?.district?.name)
  )
  const filterDistricts = [...setDistricts]
  filterDistricts.map(x => {
    districtFilter.push({ text: x, value: x })
  })
  const canEditDistrictForms = Auth.hasPermission('EDIT_DISTRICT_FORMS')
  const columns = [
    {
      title: 'Name',
      dataIndex: 'form',
      sorter: (a, b) => a.form.name.localeCompare(b.form.name),
      render: (value, record) => (
        <div>
          <div>
            {canEditDistrictForms ? (
              <Link to={`./districtform/${record.id}`}>{value?.name}</Link>
            ) : (
              <>{value?.name}</>
            )}
            &nbsp;
            {isSuperAdmin && record.hidden && <Tag color='orange'>Hidden</Tag>}
          </div>
          {isSuperAdmin && <div className='cellWithId'>{record.id}</div>}
        </div>
      )
    },
    {
      title: 'Code',
      dataIndex: 'form',
      sorter: (a, b) => a.form.formCode.localeCompare(b.form.formCode),
      render: (value, record) => record.form?.formCode
    },
    // {
    //   title: 'Sub-Roles',
    //   dataIndex: 'form',
    //   render: (value, record) => (
    //     <div>
    //       {record.subRolesApplied.map(subRole => (
    //         <Tag key={Math.random()} color='geekblue'>
    //           {subRole}
    //         </Tag>
    //       ))}
    //     </div>
    //   )
    // },
    {
      title: `District`,
      dataIndex: 'districtName',
      filters: districtFilter,
      // filterSearch: true,
      onFilter: (value, record) => record?.district?.name.includes(value),
      render: (value, record) => <>{record?.district?.name} </>
    }
  ]
  return (
    <>
      {!loading && (
        <>

          <>

            <div className='page-container'>
              <Card title='District forms' bordered={false} extra={extra}>
                <Row justify={'space-left'} style={{ marginBottom: '1rem' }}>
                  <Col span={6}>
                    <Input
                      placeholder="Search by form name or code"
                      value={value}
                      spellCheck={false}
                      onChange={e => {
                        let arr = []
                        const currValue = e.target.value;
                        setValue(currValue);
                        data?.districtForms?.filter(districtForm => {
                          const districtFormName = districtForm?.form?.name ? districtForm?.form.name.toLowerCase().trim() : '';
                          const districtFormCode = districtForm?.form?.formCode ? districtForm?.form.formCode.toLowerCase().trim() : '';

                          if (districtFormName.includes(currValue)) {
                            arr.push(districtForm)
                          }
                          else if (districtFormCode.includes(currValue)) {
                            arr.push(districtForm)
                          }
                        });
                        setDataSource(arr);
                      }}
                    />

                  </Col>
                  <Col>
                    <Button
                      type='default'
                      style={{ marginLeft: '1rem' }}
                      onClick={reset}
                    >
                      Reset
                    </Button>
                  </Col>
                </Row>
                <Table
                  columns={columns}
                  dataKey='districtForms'
                  dataSource={dataSource}
                  loading={loading}
                  //onChange={(pagination,filters,sorter,extra) => {console.log('Table onChange:',pagination,filters,sorter,extra)} }
                  pagination={{
                    showSizeChanger: true, //If excluded, antd turns this on automatically if total > 50
                    // showQuickJumper: true,
                    showTotal: (data) => `${data.toLocaleString("en")} District Forms`,
                    position: ["bottomRight"],
                    defaultPageSize: 20,
                  }}
                />
              </Card>
            </div>
          </>



        </>
      )}
    </>
  )
}

export default DistrictFormList
