// react
import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
import AuthContext from '../../../utils/authProvider'
// apollo & operations
import { useQuery, useMutation } from '@apollo/client'
import {
  QUERY_STUDENT,
  QUERY_STUDENTS
} from '../../../operations/queries/students'
import {
  INSERT_STUDENT,
  UPDATE_STUDENT
} from '../../../operations/mutations/students'

// ant design
import {
  Card,
  Form,
  Input,
  Button,
  Typography,
  Divider,
  DatePicker,
  message,
  Select
} from 'antd'

// elements
import FormItemsSuperAdmin from '../../elements/formItems/FormItemsSuperAdmin'
import FormItemSelectSchool from '../../elements/formItems/FormItemSelectSchool'
import FormItemSelectGrade from '../../elements/formItems/FormItemSelectGrade'
import FormItemSelectGender from '../../elements/formItems/FormItemSelectGender'
import FormItemSelectPrimaryLanguage from '../../elements/formItems/FormItemSelectLanguages'
import FormItemSelectEthnicity from '../../elements/formItems/FormItemSelectEthnicity'

// other libraries
import formatErrors from '../../../utils/form'
import FormContainer from '../../../components/FormContainer'
import ModalDialog from '../../../components/ModalDialog'
import DateSelector from '../../elements/common/DateSelector'
// constants
import { parentRelations } from '../../../utils/constants'
import notificationSuccess from '../../../utils/notification'

const { Paragraph } = Typography

const FormRecord = ({ data }) => {
  const history = useNavigate()
  const { id } = useParams()
  const [form] = Form.useForm()
  const [formError, setFormError] = useState()
  const [itemErrors, setItemErrors] = useState({})
  const Auth = useContext(AuthContext)
  const desktopData = Auth.desktopData
  if (desktopData === null) {
    Auth.fetchDesktopData()
  }
  const [updateStudent, { loading: updateLoading }] = useMutation(
    UPDATE_STUDENT, {
    refetchQueries: [
      {
        query: QUERY_STUDENTS,
        variables: { id: data?.student?._id }
      }
    ]
  })

  const [insertStudent, { loading: insertLoading }] = useMutation(
    INSERT_STUDENT,
    {
      refetchQueries: [
        {
          query: QUERY_STUDENTS,
          variables: { id: data.student._id }
        }
      ],
      update(cache, { data: addStudent }) {
        const data = cache.readQuery({ query: QUERY_STUDENTS })
        if (data) {
          cache.writeQuery({
            query: QUERY_STUDENTS,
            data: { students: [addStudent.insertStudent, ...data.students] }
          })
        }
      }
    }
  )

  const continueEditing = _id => _id && history(`/student/${_id}`)
  const [studentAge, setStudentAge] = useState([data.student.age, ''])
  const handleDOBChange = (date, dateString) => {
    if (!date) {
      setStudentAge('')
      return
    } else {
      setStudentAge(moment().diff(date, 'years') + ' years')
    }
  }
  const loadParent1FromStudent = () => {
    form.setFieldsValue({ parent1LastName: form.getFieldValue('lastName') })
    form.setFieldsValue({
      parent1PrimaryLanguage: form.getFieldValue('primaryLanguage')
    })
    form.setFieldsValue({
      parent1PhoneNumber: form.getFieldValue('phoneNumber')
    })
    form.setFieldsValue({ parent1Address: form.getFieldValue('address') })
    form.setFieldsValue({ parent1City: form.getFieldValue('city') })
    form.setFieldsValue({ parent1State: form.getFieldValue('state') })
    form.setFieldsValue({ parent1City: form.getFieldValue('city') })
    form.setFieldsValue({ parent1Country: form.getFieldValue('country') })
    form.setFieldsValue({ parent1Zip: form.getFieldValue('zip') })
  }
  const loadParent2FromStudent = () => {
    form.setFieldsValue({ parent2LastName: form.getFieldValue('lastName') })
    form.setFieldsValue({
      parent2PrimaryLanguage: form.getFieldValue('primaryLanguage')
    })
    form.setFieldsValue({
      parent2PhoneNumber: form.getFieldValue('phoneNumber')
    })
    form.setFieldsValue({ parent2Address: form.getFieldValue('address') })
    form.setFieldsValue({ parent2City: form.getFieldValue('city') })
    form.setFieldsValue({ parent2State: form.getFieldValue('state') })
    form.setFieldsValue({ parent2City: form.getFieldValue('city') })
    form.setFieldsValue({ parent2Country: form.getFieldValue('country') })
    form.setFieldsValue({ parent2Zip: form.getFieldValue('zip') })
  }

  const onFinish = async values => {
    values.currentGoalIds = data.student.currentGoalIds
    values.priorGoalIds = data.student.priorGoalIds
    try {
      clearErrors()
      const mutated = id
        ? await updateStudent({ variables: { id: id, studentInput: values } })
        : await insertStudent({ variables: values });
      console.log(values, 'what are values')
      await updateStudent({ variables: { id: mutated.data.insertStudent?.id, studentInput: values } })
      // ModalDialog.success({
      //   title: "Success",
      //   content: `The student has been ${id ? "updated" : "added"}.`,
      //   okText: "Return to listing",
      //   onOk: () => history("/students"),
      //   cancelText: "Continue editing",
      //   onCancel: () => continueEditing(mutated.data.insertStudent?.id),
      // })
      notificationSuccess(`The student has been ${mutated ? 'updated' : 'added'}.`)
      Auth.fetchDesktopData()
    } catch (e) {
      if (e == 'Cannot read properties of undefined (reading __typename)') {
        console.log('hello')
      }
      message.error(
        'Error(s) need to be corrected before the student can be updated.'
      )
      setErrors(formatErrors(e))
    }
  }

  const setErrors = errors =>
    errors.itemErrors
      ? setItemErrors(errors.itemErrors)
      : setFormError(errors.formError)

  const clearErrors = () => {
    setFormError()
    setItemErrors({})
  }
  return (
    <Form
      form={form}
      id='studentForm'
      name='studentForm'
      onFinish={onFinish}
      preserve={false}
      initialValues={data.student}
      //scrollToFirstError
      labelCol={{ sm: 7, md: 5, lg: 5, xl: 4, xxl: 3 }}
      wrapperCol={{ sm: 10, md: 7, lg: 8, xl: 6, xxl: 6 }}
    >
      <Card
        title='Student Information'
        style={{
          marginBottom: 30
        }}
      >
        <FormItemSelectSchool
          label='School'
          name='schoolIds'
          validateStatus={itemErrors.schoolId ? 'error' : ''}
          help={itemErrors.schoolId}
        />

        <FormItemSelectGrade
          label='Grade'
          name='grade'
          validateStatus={itemErrors.grade ? 'error' : ''}
          help={itemErrors.grade}
        />

        <Form.Item
          label='Student code'
          name='studentCode'
          hasFeedback
          validateStatus={itemErrors.studentCode ? 'error' : ''}
          help={itemErrors.studentCode}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='First name'
          name='firstName'
          hasFeedback
          validateStatus={itemErrors.firstName ? 'error' : ''}
          help={itemErrors.firstName}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Middle name'
          name='middleName'
          hasFeedback
          validateStatus={itemErrors.middleName ? 'error' : ''}
          help={itemErrors.middleName}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Last name'
          name='lastName'
          hasFeedback
          validateStatus={itemErrors.lastName ? 'error' : ''}
          help={itemErrors.lastName}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Date of birth'
          name='dateOfBirth'
          validateStatus={itemErrors.dateOfBirth ? 'error' : ''}
          help={itemErrors.dateOfBirth}
        >
          <DatePicker
            onChange={handleDOBChange}
            format={[
              'MM-DD-YYYY',
              'MM/DD/YYYY',
              'MM.DD.YY',
              'M-D-YYYY',
              'M/D/YYYY',
              'M.D.YYYY',
              'MM.DD.YYYY',
              'MM-DD-YY',
              'MM/DD/YY',
              'M-D-YY',
              'M/D/YY',
              'M.D.YY',
              'MMDDYYYY',
              'MMDDYY'
            ]}
          />
        </Form.Item>

        <Form.Item label='Age' id='Age'>
          <Input
            type='text'
            value={studentAge}
            disabled={true}
            readOnly={true}
          />
        </Form.Item>

        <Form.Item
          label='Medicaid number'
          name='medicaidNumber'
          hasFeedback
          validateStatus={itemErrors.medicaidNumber ? 'error' : ''}
          help={itemErrors.medicaidNumber}
        >
          <Input />
        </Form.Item>

        <FormItemSelectGender
          label='Gender'
          name='gender'
          validateStatus={itemErrors.gender ? 'error' : ''}
          help={itemErrors.gender}
        />

        <FormItemSelectEthnicity
          label='Ethnicity'
          name='ethnicity'
          validateStatus={itemErrors.ethnicity ? 'error' : ''}
          help={itemErrors.ethnicity}
        />

        <FormItemSelectPrimaryLanguage
          label='Primary Language'
          name='primaryLanguage'
          validateStatus={itemErrors.primaryLanguage ? 'error' : ''}
          help={itemErrors.primaryLanguage}
        />

        <Form.Item
          label='Phone number'
          name='phoneNumber'
          hasFeedback
          validateStatus={itemErrors.phoneNumber ? 'error' : ''}
          help={itemErrors.phoneNumber}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Address'
          name='address'
          hasFeedback
          validateStatus={itemErrors.address ? 'error' : ''}
          help={itemErrors.address}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='City'
          name='city'
          hasFeedback
          validateStatus={itemErrors.city ? 'error' : ''}
          help={itemErrors.city}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='State'
          name='state'
          hasFeedback
          validateStatus={itemErrors.state ? 'error' : ''}
          help={itemErrors.state}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Country'
          name='country'
          hasFeedback
          validateStatus={itemErrors.country ? 'error' : ''}
          help={itemErrors.country}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Zip'
          name='zip'
          hasFeedback
          validateStatus={itemErrors.zip ? 'error' : ''}
          help={itemErrors.zip}
        >
          <Input />
        </Form.Item>
      </Card>

      <Card
        title='Parent #1 Information'
        style={{
          marginBottom: 30
        }}
      >
        <Button
          type='primary'
          style={{ margin: '16px 8px' }}
          onClick={loadParent1FromStudent}
        >
          Load Student Information
        </Button>

        <Form.Item
          label='First name'
          name='parent1FirstName'
          hasFeedback
          validateStatus={itemErrors.parent1FirstName ? 'error' : ''}
          help={itemErrors.parent1FirstName}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Last name'
          name='parent1LastName'
          hasFeedback
          validateStatus={itemErrors.parent1LastName ? 'error' : ''}
          help={itemErrors.parent1LastName}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Relationship'
          name='parent1Relationship'
          validateStatus={itemErrors.parent1Relationship ? 'error' : ''}
          help={itemErrors.parent1Relationship}
          hasFeedback
        >
          <Select style={{ width: '100%' }} allowClear>
            {parentRelations.map(e => (
              <Select.Option key={e} value={e}>
                {e}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <FormItemSelectPrimaryLanguage
          label='Primary Language'
          name='parent1PrimaryLanguage'
          validateStatus={itemErrors.parent1PrimaryLanguage ? 'error' : ''}
          help={itemErrors.parent1PrimaryLanguage}
        />

        <Form.Item
          label='Phone number'
          name='parent1PhoneNumber'
          hasFeedback
          validateStatus={itemErrors.parent1PhoneNumber ? 'error' : ''}
          help={itemErrors.parent1PhoneNumber}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Address'
          name='parent1Address'
          hasFeedback
          validateStatus={itemErrors.parent1Address ? 'error' : ''}
          help={itemErrors.parent1Address}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='City'
          name='parent1City'
          hasFeedback
          validateStatus={itemErrors.parent1City ? 'error' : ''}
          help={itemErrors.parent1City}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='State'
          name='parent1State'
          hasFeedback
          validateStatus={itemErrors.parent1State ? 'error' : ''}
          help={itemErrors.parent1State}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Country'
          name='parent1Country'
          hasFeedback
          validateStatus={itemErrors.parent1Country ? 'error' : ''}
          help={itemErrors.parent1Country}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Zip'
          name='parent1Zip'
          hasFeedback
          validateStatus={itemErrors.parent1Zip ? 'error' : ''}
          help={itemErrors.parent1Zip}
        >
          <Input />
        </Form.Item>
      </Card>

      <Card
        title='Parent #2 Information'
        style={{
          marginBottom: 30
        }}
      >
        <Button
          type='primary'
          style={{ margin: '16px 8px' }}
          onClick={loadParent2FromStudent}
        >
          Load Student Information
        </Button>

        <Form.Item
          label='First name'
          name='parent2FirstName'
          hasFeedback
          validateStatus={itemErrors.parent2FirstName ? 'error' : ''}
          help={itemErrors.parent2FirstName}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Last name'
          name='parent2LastName'
          hasFeedback
          validateStatus={itemErrors.parent2LastName ? 'error' : ''}
          help={itemErrors.parent2LastName}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Relationship'
          name='parent2Relationship'
          validateStatus={itemErrors.parent2Relationship ? 'error' : ''}
          help={itemErrors.parent2Relationship}
          hasFeedback
        >
          <Select style={{ width: '100%' }} allowClear>
            {parentRelations.map(e => (
              <Select.Option key={e} value={e}>
                {e}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <FormItemSelectPrimaryLanguage
          label='Primary Language'
          name='parent2PrimaryLanguage'
          validateStatus={itemErrors.parent2PrimaryLanguage ? 'error' : ''}
          help={itemErrors.parent2PrimaryLanguage}
        />

        <Form.Item
          label='Phone number'
          name='parent2PhoneNumber'
          hasFeedback
          validateStatus={itemErrors.parent2PhoneNumber ? 'error' : ''}
          help={itemErrors.parent2PhoneNumber}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Address'
          name='parent2Address'
          hasFeedback
          validateStatus={itemErrors.parent2Address ? 'error' : ''}
          help={itemErrors.parent2Address}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='City'
          name='parent2City'
          hasFeedback
          validateStatus={itemErrors.parent2City ? 'error' : ''}
          help={itemErrors.parent2City}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='State'
          name='parent2State'
          hasFeedback
          validateStatus={itemErrors.parent2State ? 'error' : ''}
          help={itemErrors.parent2State}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Country'
          name='parent2Country'
          hasFeedback
          validateStatus={itemErrors.parent2Country ? 'error' : ''}
          help={itemErrors.parent2Country}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Zip'
          name='parent2Zip'
          hasFeedback
          validateStatus={itemErrors.parent2Zip ? 'error' : ''}
          help={itemErrors.parent2Zip}
        >
          <Input />
        </Form.Item>
      </Card>

      <FormItemsSuperAdmin data={data.student} hasHidden />

      {formError && <Paragraph type='danger'>{formError}</Paragraph>}

      <Form.Item>
        <Button
          type='primary'
          htmlType='submit'
          loading={insertLoading || updateLoading}
        >
          {id ? 'Update' : 'Add'}
        </Button>
      </Form.Item>
    </Form>
  )
}

FormRecord.propTypes = {
  data: PropTypes.object.isRequired
}

const StudentForm = props => {
  const { id } = useParams()

  const { data, loading } = useQuery(QUERY_STUDENT, {
    variables: { id, includeGoals: true },
    skip: !id
  })

  const initialValues = {
    student: {
      studentCode: '',
      middleName: '',
      firstName: '',
      lastName: '',
      medicaidNumber: '',
      dateOfBirth: '',
      primaryLanguage: '',
      gender: '',
      ethnicity: '',
      phoneNumber: '',
      address: '',
      city: '',
      state: 'IL',
      country: 'United States',
      zip: '',
      parent1FirstName: '',
      parent1LastName: '',
      parent1Relationship: '',
      parent1PrimaryLanguage: '',
      parent1PhoneNumber: '',
      parent1Address: '',
      parent1City: '',
      parent1State: 'IL',
      parent1Country: 'United States',
      parent1Zip: '',
      parent2FirstName: '',
      parent2LastName: '',
      parent2Relationship: '',
      parent2PrimaryLanguage: '',
      parent2PhoneNumber: '',
      parent2Address: '',
      parent2City: '',
      parent2State: 'IL',
      parent2Country: 'United States',
      parent2Zip: '',
      hidden: false,
      schoolIds: '',
      gradeId: ''
    }
  }

  const initializedData = () => {
    if (!data) return false

    // Initialize form data with good defaults
    return {
      student: {
        ...data?.student,
        dateOfBirth: data?.student.dateOfBirth
          ? moment(data?.student.dateOfBirth)
          : '',
        primaryLanguage: data?.student.primaryLanguage || '',
        ethnicity: data?.student.ethnicity || '',
        gender: data?.student.gender || '',
        parent1PrimaryLanguage: data?.student.parent1PrimaryLanguage || '',
        parent2PrimaryLanguage: data?.student.parent2PrimaryLanguage || ''
      }
    }
  }

  return (
    <FormContainer
      loading={loading}
      form={<FormRecord data={initializedData() || initialValues} />}
    />
  )
}

export default StudentForm
