import { gql } from "@apollo/client";

export const INSERT_USER = gql`
  mutation insertUser(
    $email: String!
    $roleIds: [ID!]
    $userAdminDistrictIds: [ID!]
    $userAdminSchoolIds: [ID!]
    $userGeneralSchoolIds: [ID!]
    $subRoleId: ID
    $hidden: Boolean
  ) {
    insertUser(
      userInput: {
        email: $email
        roleIds: $roleIds
        userAdminDistrictIds: $userAdminDistrictIds
        userAdminSchoolIds: $userAdminSchoolIds
        userGeneralSchoolIds: $userGeneralSchoolIds
        subRoleId: $subRoleId
        hidden: $hidden
      }
    ) {
      id
      email
      roleIds
      subRoleId
      userAdminDistrictIds
      userAdminSchoolIds
      userGeneralSchoolIds
      hidden
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($id: ID!, $userInput: UserInput) {
    updateUser(userId: $id, userInput: $userInput) {
      id
      email
      roleIds
      subRoleId
      userAdminDistrictIds
      userAdminSchoolIds
      userGeneralSchoolIds
      desktopStudentIds
      hidden
    }
  }
`;

export const UPDATE_ME = gql`
  mutation updateMe($updateMeInput: UpdateMeInput!) {
    updateMe(updateMeInput: $updateMeInput) {
      currentDistrictId
      currentSchoolId
    }
  }
`;

export const EMAIL_USER_PASSWORD_RESET_LINK = gql`
  mutation emailUserPasswordResetLink($email: String!) {
    emailUserPasswordResetLink(email: $email)
  }
`;

export const EMAIL_INVITE_LINK = gql`
  mutation emailInviteLink($id: ID!) {
    emailInviteLink(userId: $id)
  }
`;

export const TEST_PASSWORD_RESET_TOKEN = gql`
  mutation ($passwordResetToken: String!) {
    testPasswordResetToken(passwordResetToken: $passwordResetToken)
  }
`;

export const SAVE_NEW_PASSWORD_WITH_PASSWORD_TOKEN = gql`
  mutation saveNewPasswordWithPasswordResetToken(
    $passwordResetToken: String!
    $saveNewPasswordWithPasswordResetTokenInput: SaveNewPasswordWithPasswordResetTokenInput!
  ) {
    saveNewPasswordWithPasswordResetToken(
      passwordResetToken: $passwordResetToken
      saveNewPasswordWithPasswordResetTokenInput: $saveNewPasswordWithPasswordResetTokenInput
    )
  }
`;

export const EMAIL_ISSUE_REPORT = gql`
  mutation emailIssueReport(
    $userId: ID!
    $description: String!
  ) {
    emailIssueReport(
      userId: $userId
      description: $description
    )
  }
`;

