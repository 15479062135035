import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { IepFormContext } from '../../../utils/iepFormProvider'
import { useQuery, useMutation } from '@apollo/client'
import { Form, Select, Input, Divider } from 'antd'
import {
  QUERY_LOOKUPS,
  QUERY_LOOKUP_TYPES
} from '../../../operations/queries/lookup'
import { PlusOutlined } from '@ant-design/icons'
import { INSERT_LOOKUP } from '../../../operations/mutations/lookup'
import AuthContext, { AuthProvider } from '../../../utils/authProvider'
const { Option } = Select

const LookupSelectGeneric = ({
  type,
  label,
  name,
  validation,
  placeholder,
  singleMode,
  student,
  initialValue,
  ...props
}) => {
  const Auth = useContext(AuthContext)
  const districtAdmin = Auth.hasRole('DISTRICTS_ADMIN')
  const superAdmin = Auth.hasRole('SUPER_ADMIN')
  // super admin no longer able to make meetings, possibly add default meeting type to fix
  const { loading: lookupLoading, data: lookupData } = useQuery(QUERY_LOOKUPS, {
    variables: {
      type,
      districtId: districtAdmin
        ? Auth?.currentDistrictId?.id
        : Auth?.districtsAdmin[0]?.id,
      skip: superAdmin
    }
  })

  const { loading: typesLoading, data: typeData } = useQuery(
    QUERY_LOOKUP_TYPES,
    {
      variables: {
        type,
        districtId: districtAdmin
          ? Auth?.currentDistrictId?.id
          : Auth?.districtsAdmin[0]?.id,
        skip: superAdmin
      }
    }
  )
  const [lookups, setLookups] = useState([])
  const [customLookup, setCustomLookup] = useState('')
  const [error, setError] = useState('')
  const onNameChange = event => {
    let regex = new RegExp(/^[a-zA-Z0-9\(\)\s/'.,-]*$/)
    // Skips the | character if in the input
    if (event.target.value.indexOf('|') >= 0) {
      // Do nothing
    } else {
      let str = event.target.value
      let result = str.match(regex)
      if (result != null) {
        setCustomLookup(event.target.value)
        setError('')
      } else {
        setError('Invalid Characters')
      }
    }
  }

  const [insertLookup] = useMutation(INSERT_LOOKUP)

  const addTheLookup = async () => {
    if (error === '') {
      setLookups([...lookups, { value: customLookup }])
      setCustomLookup('')
      let typeId = ''
      typeId = typeData?.lookupTypes.map(e => {
        if (e.name === type) {
          typeId = e.id
        }
        return typeId
      })
      try {
        const mutationVariables = superAdmin
          ? {
            typeId: typeId,
            value: customLookup
          }
          : {
            typeId: typeId,
            districtId: districtAdmin
              ? Auth?.currentDistrictId?.id
              : Auth?.districtsAdmin[0]?.id,
            value: customLookup
          }
        await insertLookup({ variables: mutationVariables })
      } catch (e) {
        console.log(e, 'error')
      }
    }
  }

  useEffect(() => {
    if (lookupData && error === '') {
      setLookups(lookupData.lookups)
    }
  }, [lookupData])
  return (
    <>
      {lookups != undefined && typeData != undefined ? (
        <>
          <Form.Item
            label={label}
            name={name}
            initialValue={initialValue}
            {...props}
            loading={typesLoading && lookupLoading}
            rules={[
              {
                required: true,
                message: 'Meeting Type is Required'
              }
            ]}
          >
            <Select
              loading={lookupLoading && typesLoading}
              size='large'
              showSearch
              style={{ width: '100%' }}
              mode={!singleMode && 'multiple'}
              allowClear
              placeholder={placeholder}
              filterOption={(input, option) =>
                option.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
              }
              filterSort={(optionA, optionB) =>
                optionA.children
                  .toString().toLowerCase()
                  .localeCompare(optionB.children.toString().toLowerCase())
              }
              dropdownRender={menu => (
                <div>
                  {menu}
                  {Auth.hasRole('SUPER_ADMIN', 'DISTRICTS_ADMIN') ? (
                    <>
                      <Divider style={{ margin: '4px 0' }} />
                      <div
                        style={{
                          display: 'flex',
                          flexWrap: 'nowrap',
                          padding: 8
                        }}
                      >
                        <Input
                          style={{ flex: 'auto' }}
                          value={customLookup}
                          onChange={onNameChange}
                        />
                        <a
                          style={{
                            flex: 'none',
                            padding: '8px',
                            display: 'block',
                            cursor: 'pointer'
                          }}
                          onClick={addTheLookup}
                        >
                          <PlusOutlined /> Add item
                        </a>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  {error === '' ? (
                    <></>
                  ) : (
                    <>
                      <Divider style={{ margin: '4px 0' }} />
                      <div
                        style={{
                          display: 'flex',
                          flexWrap: 'nowrap',
                          padding: 8,
                          color: 'red'
                        }}
                      >
                        {error}
                      </div>
                    </>
                  )}
                </div>
              )}
            >
              { }
              {lookups.map((x, index) => (
                <Option key={index} value={x.value}>
                  {x.value}
                </Option>
              ))}
            </Select>
          </Form.Item>{' '}
        </>
      ) : (
        <>loading...</>
      )}
    </>
  )
}

LookupSelectGeneric.propTypes = {
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  validation: PropTypes.object.isRequired,
  placeholder: PropTypes.string.isRequired,
  singleMode: PropTypes.bool,
  initialValue: PropTypes.string
}

export default LookupSelectGeneric
