// react
import React, { useState, useContext, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'

// Contexts
import AuthContext from '../../../utils/authProvider'
import { useLazyQuery, useQuery } from '@apollo/client'
import { useReadQuery } from '@apollo/client'
// ant design
import { Card, Button, Tag, Tooltip, Space, Input, Table, Row, Col, Modal, Typography, Form, Select, DatePicker, Switch } from 'antd'

// operations
import { QUERY_STUDENTS } from '../../../operations/queries/students'
import { INSERT_MEETING } from '../../../operations/mutations/meetings'
import { UPDATE_STUDENT_MEETING_GENERAL } from '../../../operations/mutations/meetings'
// components
import LookupSelectGeneric from '../../elements/common/LookupSelectGeneric'
import { DesktopOutlined, PlusOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { ADD_STUDENT_TO_DESKTOP } from '../../../operations/mutations/meetings'
import {
  notificationError,
  notificationInfoAlt,
  notificationSuccess
} from '../../../utils/notification'
import moment from 'moment'

const AddMeetingForm = ({ student,
  visible, addMeeting, setAddMeetingVisible
}) => {
  const [form] = Form.useForm()

  const [errorMessage, setErrorMessage] = useState()
  const [insertMeeting, { loading: insertMeetingLoading }] =
    useMutation(INSERT_MEETING)
  const [
    updateStudentMeetingGeneral,
    { loading: updateStudentMeetingGeneralLoading }
  ] = useMutation(UPDATE_STUDENT_MEETING_GENERAL)
  const { Text } = Typography
  const history = useNavigate()
  const afterCreated = meetingId => {
    visible = false
    history('/student-form/' + meetingId)
  }
  const onCancel = () => {
    setAddMeetingVisible(false)
  }
  return (
    <Modal
      open={visible}
      title='New Meeting/Plan'
      okText='Add'
      confirmLoading={
        insertMeetingLoading && updateStudentMeetingGeneralLoading
      }
      cancelText='Cancel'
      onCancel={onCancel}
      onOk={() => {
        setErrorMessage()
        form
          .validateFields()
          .then(async values => {
            form.resetFields()
            let allowed = true
            if (
              values.meetingType === '504 Plan' &&
              values.isActiveIep === true
            ) {
              allowed = false
              notificationError(
                '504 Plan meetings are not allowed to be part of the active IEP'
              )
            }
            if (allowed === true) {
              const response = await insertMeeting({
                variables: {
                  studentMeetingGeneralInput: {
                    schoolId: values.schoolId,
                    studentId: student.id,
                    studentFormIds: [],
                    meetingType: values.meetingType,
                    meetingDate: values.date.format('MM-DD-YYYY'),
                    isActiveIep: values.isActiveIep,
                    iepMeetingId: student.currentIepMeetingId,
                    parentNotifications: []
                  }
                }
              })
              afterCreated(response?.data?.insertStudentMeetingGeneral.id)

              student?.studentMeetingsGeneral?.map(async e => {
                //mutate all the meetings if the new meeting is an active iep and any existing meeting is active,
                // if no meeting type ignore (crash bandaid for old data)
                if (
                  e.meetingType != null &&
                  e.isActiveIep === true &&
                  values.isActiveIep === true
                ) {
                  await updateStudentMeetingGeneral({
                    variables: {
                      id: e.id,
                      schoolId: e.schoolId,
                      studentId: e.studentId,
                      studentFormIds: e.studentFormIds,
                      meetingType: e.meetingType,
                      meetingDate: e.meetingDate,
                      isActiveIep: false,
                      iepMeetingId: e.currentIepMeetingId,
                      parentNotifications: []
                    }
                  })
                }
              })
              Auth.fetchDesktopData()
            }
          })
          .catch(info => {
            console.log(info, 'error')
            setErrorMessage('An error occured while adding the meeting.')
          })
      }}
    >
      <Form
        form={form}
        layout='vertical'
        name='addMeetingForm'
        initialValues={{
          schoolId:
            student?.schools?.length === 1 ? student?.schools[0].id : undefined
        }}
      >
        <Form.Item
          label='School'
          name='schoolId'
          rules={[
            {
              required: true,
              message: 'Please select a school'
            }
          ]}
        >
          <Select>
            <Select.Option key={student?.schoolId} value={student?.schoolId}>
              {student?.schoolName}
            </Select.Option>
          </Select>
        </Form.Item>

        <LookupSelectGeneric
          singleMode={true}
          name='meetingType'
          type='Meeting Type'
          placeholder='Please select a meeting/plan type'
        />
        <Form.Item name='date' label='Meeting Date/Plan Start Date'>
          <DatePicker
            format={[
              'MM-DD-YYYY',
              'MM/DD/YYYY',
              'MM.DD.YY',
              'M-D-YYYY',
              'M/D/YYYY',
              'M.D.YYYY',
              'MM.DD.YYYY',
              'MM-DD-YY',
              'MM/DD/YY',
              'M-D-YY',
              'M/D/YY',
              'M.D.YY',
              'MMDDYYYY',
              'MMDDYY'
            ]}
          />
        </Form.Item>
        <Form.Item
          name='isActiveIep'
          label='Active'
          rules={[
            {
              required: true
            }
          ]}
          initialValue={true}
        >
          <Switch defaultChecked={true} />
        </Form.Item>
        {errorMessage && <Text type='danger'>{errorMessage}</Text>}
      </Form>
    </Modal>
  )
}

const StudentList = () => {

  const Auth = useContext(AuthContext)
  const desktopData = Auth.desktopData
  if (desktopData === null) {
    Auth.fetchDesktopData()
  }

  const isSuperAdmin = Auth.hasRole('SUPER_ADMIN')
  const isSchoolsGeneral = Auth.hasRole('SCHOOLS_GENERAL')
  const [criterias, setCriterias] = useState({})
  const [addMeetingVisible, setAddMeetingVisible] = useState(false)
  const [addStudentToMyDesktop] = useMutation(ADD_STUDENT_TO_DESKTOP, {
    onCompleted: () => {
      Auth.fetchDesktopData()
      notificationSuccess('Student added to dashboard', '')
    },
    onError: (e) => {
      notificationError('Failed to add student to dashboard', e)
    }
  })
  const canAddStudents = Auth.hasPermission('ADD_STUDENTS')

  const extra = (
    <>
      {canAddStudents && (
        <Tooltip title='Add new student'>
          <Link to='../student/add'>
            <Button type='primary'>Add Student</Button>
          </Link>
        </Tooltip>
      )}
    </>
  )

  const [pagination, setPagination] = useState({ offset: 0, limit: 10 });

  const [getStudentData, { loading, data, error }] = useLazyQuery(QUERY_STUDENTS, {
    variables: { ...criterias, ...pagination },
    fetchPolicy: 'cache-first',
    enabled: false,
    staleTime: Infinity
  });
  const [dataSource, setDataSource] = useState(data?.students);
  const [value, setValue] = useState('');

  // const data = useQuery({ query: QUERY_STUDENTS })
  useEffect(async () => {
    await getStudentData('students')
    setDataSource(data?.students)
  }, [data]);
  const getFullName = record =>
    isSchoolsGeneral ? record.fullName : record.fullNameGeneral
  const getGrade = record => (record.grade ? record.grade : '')
  const getNumericGrade = (grade) => {
    let gradeValue = -99
    if (grade != undefined || null) {
      if (grade.slice(0, 5) === 'Grade') {
        gradeValue = parseInt(grade.replace('Grade', '').trim()) //Grade 1-12
      } else {
        switch (grade.toLowerCase()) {
          case 'early childhood':
            gradeValue = -3
            break
          case 'pre kindergarten':
            gradeValue = -2
            break
          case 'kindergarten':
            gradeValue = -1
            break
          default:
            gradeValue = -98
            break
        }
      }
    }
    return gradeValue
  }
  const filterTheSchools = () => {
    let setSchools = new Set()
    let antdSchools = []
    data?.students?.map(e => {
      if (e.schoolName != undefined || null) {
        setSchools.add(e.schoolName)
      }
    })
    const filterSchools = [...setSchools]
    filterSchools?.map(e => {
      antdSchools.push({ text: e, value: e })
    })
    return antdSchools
  }

  const filterTheGrades = () => {
    let gradesList = []
    data?.students?.map(student => {  //Load an array of grades with numeric equivalent
      if (student.grade != undefined || null) {
        const gradeValue = getNumericGrade(student.grade)
        gradesList.push([student.grade, gradeValue])
      }
    })
    gradesList = gradesList.sort((a, b) => a[1] - b[1]);  //Sort array of grades by gradeValue

    const gradesSet = new Set() //Load a set of unique grades from the list of grades
    gradesList.map(grade => {
      gradesSet.add(grade[0])
    })

    const antdGrades = [] //Load the antd grades filters
    gradesSet.forEach((grade) => {
      antdGrades.push({ text: grade, value: grade })
    })
    return antdGrades
  }

  const getStudentCode = record => (record.studentCode ? record.studentCode : '')
  //const getSchoolName = record => (record.schools ? record.schools[0].name : '')
  const getSchoolName = record => (record.schoolName ? record.schoolName : '')
  const getDateOfBirth = record => moment(record.dateOfBirth).format('M/D/YYYY')


  const columns = [
    {
      title: 'Full name',
      dataIndex: 'fullName',
      sorter: {
        compare: (a, b) => a.fullName.localeCompare(b.fullName),
        multiple: 2,
      },
      render: (value, record) => (
        <>
          <Link to={`../student/${record.id}`}>{getFullName(record)}</Link>
          &nbsp;
          {isSuperAdmin && record.hidden && <Tag color='orange'>Hidden</Tag>}

        </>
      )
    },
    {
      title: 'Student Code',
      dataIndex: 'studentCode',
      sorter: (a, b) => getStudentCode(a).localeCompare(getStudentCode(b)),
      render: (value, record) => <>{getStudentCode(record)} </>
    },
    {
      title: 'Date of Birth',
      dataIndex: 'dateOfBirth',
      sorter: {
        //sorter: (a, b) => getDateOfBirth(a).localeCompare(getDateOfBirth(b)), //Original. 10.8sec to sort 61k recs
        compare: (a, b) => new Date(a.dateOfBirth) - new Date(b.dateOfBirth),  //Revised. 2.3sec to sort 61k recs
        multiple: 1,
      },
      render: (value, record) => <>{getDateOfBirth(record)}</>
    },
    {
      title: 'Grade',
      dataIndex: 'grade',
      sorter: {
        compare: (a, b) => getNumericGrade(a.grade) - getNumericGrade(b.grade),
        multiple: 3,
      },
      filters: filterTheGrades(),
      onFilter: (value, record) => {
        return record.grade ? record.grade === value : false
      },
      //sorter: (a, b) => getGrade(a).localeCompare(getGrade(b)),
      render: (value, record) => <>{getGrade(record)} </>
    },
    {
      title: 'School',
      dataIndex: 'schoolName',
      sorter: {
        compare: (a, b) => getSchoolName(a).localeCompare(getSchoolName(b)),
        multiple: 4,
      },
      filters: filterTheSchools(),
      onFilter: (value, records) => {
        if (records?.schoolName) {
          return records.schoolName.indexOf(value) === 0
        }
      },
      render: (value, record) => <>{getSchoolName(record)} </>
    },
    {
      title: <div style={{ textAlign: 'center', width: '199%', }}>Actions</div>,
      colSpan: 2,
    },
    {
      // title: <div style={{ textAlign: 'center' }}>Actions</div>,
      // onCell: sharedOnCell,
      colSpan: 2,
      render: (records, record, id) => {
        // const menu = <Menu>{meetingItems(records, true)}</Menu>
        return (
          <>
            <Space size='small'>
              <Button type='primary' onClick={() => addMeeting(record)}>
                <PlusOutlined />
                Add a Meeting/Plan
              </Button>
            </Space>
          </>
        )
      }
    },
    {
      name: 'Desktop',
      title: <div ></div>,
      colSpan: 0,
      dataIndex: 'id',
      render: id => (
        <Space size='middle'>
          <Button
            type='primary'
            onClick={() => {

              addStudentToMyDesktop({ variables: { studentId: id } })
            }}
          >
            Add to Dashboard <DesktopOutlined />
          </Button>
        </Space>
      )
    }
  ]
  const reset = () => {
    setDataSource(data.students)
    setValue('')
  }
  const history = useNavigate()

  const [addMeetingStudent, setAddMeetingStudent] = useState()
  const addMeeting = (record) => {
    setAddMeetingVisible(true)
    setAddMeetingStudent(record)
  }
  const afterMeetingCreated = meetingId => {
    setAddMeetingVisible(false)
    setAddMeetingStudent()
    history('/student-form/' + meetingId)
  }
  return (
    <>

      <div className='page-container'>
        <Card title='Students' bordered={false} extra={extra}>
          <Row justify={'space-left'} style={{ marginBottom: '1rem' }}>
            <Col span={6}>
              <Input
                placeholder="Search by name, school code or date of birth"
                value={value}
                spellCheck={false}
                onChange={e => {
                  const currValue = e.target.value;
                  setValue(currValue);
                  let arr = []
                  data?.students?.filter(person => {
                    let [word1, word2] = currValue.split(' ');
                    word1 = word1 ? word1.trim().toLowerCase() : '';
                    word2 = word2 ? word2.trim().toLowerCase() : '';
                    const firstName = person.firstName ? person.firstName.toLowerCase().trim() : '';
                    const lastName = person.lastName ? person.lastName.toLowerCase().trim() : '';
                    const studentCode = person.studentCode ? person.studentCode.toString().toLowerCase().trim() : '';
                    const dateOfBirth = person.dateOfBirth ? new Date(person.dateOfBirth).toLocaleDateString("en-US") : '';
                    if (!word2) { //Single word search.
                      if (
                        firstName.includes(word1) ||
                        lastName.includes(word1) ||
                        studentCode.includes(word1) ||
                        dateOfBirth.substring(0, word1.length) === (word1)
                      ) {
                        arr.push(person)
                      }
                    } else {  //Two word search.  Can be first/last or last/first.
                      if (
                        (firstName === word1 &&
                          lastName.includes(word2)) ||
                        (lastName === word1 &&
                          firstName.includes(word2))
                      ) {
                        arr.push(person)
                      }
                    }
                  });
                  setDataSource(arr);
                }}
              />

            </Col>
            <Col>
              <Button
                type='default'
                style={{ marginLeft: '1rem' }}
                onClick={reset}
              >
                Reset
              </Button>
            </Col>
          </Row>
          <AddMeetingForm
            student={addMeetingStudent}
            visible={addMeetingVisible}
            setAddMeetingVisible={setAddMeetingVisible}
            addMeeting={addMeeting}
            afterCreated={afterMeetingCreated}
          />
          <Table
            columns={columns}
            dataKey='students'
            dataSource={dataSource}
            loading={loading}
            //onChange={(pagination,filters,sorter,extra) => {console.log('Table onChange:',pagination,filters,sorter,extra)} }
            pagination={{
              showSizeChanger: true, //If excluded, antd turns this on automatically if total > 50
              // showQuickJumper: true,
              showTotal: (data) => `${data.toLocaleString("en")} Students`,
              position: ["bottomRight"],
              defaultPageSize: 20,
            }}
          />
        </Card>
      </div>
    </>
  )
}

export default StudentList
