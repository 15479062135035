// react
import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'

// apollo
import { useQuery, useLazyQuery } from '@apollo/client'

// react router
import { Link, useLocation } from 'react-router-dom'

// contexts
import AuthContext from '../../utils/authProvider'

// ant design
import { Menu } from 'antd'
import {
  DesktopOutlined,
  FileOutlined,
  UserOutlined,
  TableOutlined
} from '@ant-design/icons'

// operations
import { QUERY_DISTRICT_REPORTS_LIST } from '../../operations/queries/districts'  //districtReports shared by all users in the district
import { QUERY_USER_DISTRICT_REPORTS_LIST } from '../../operations/queries/districts' //userDistrictReports available only to logged in user

const SideMenu = ({ mode, theme }) => {
  const Auth = useContext(AuthContext)
  const location = useLocation()
  //const { userId, currentDistrictId } = Auth;
  const userId = '640c9fd536dba856e0df0d35' //For testing
  const currentDistrictId = '63a0d40ee026754af40b9b42'  //Why is currentDistrictId null in Auth?


  //Existing reports.  Will be removed
  const reportsMenu = () => {
    // my reports query
    const { data: userDistrictReportsList, loading: myLoading } = useQuery(QUERY_USER_DISTRICT_REPORTS_LIST, {
      variables: { userId: userId, districtIds: [currentDistrictId] },
    })
    // shared loading
    const { data: districtReportsList, loading: sharedLoading } = useQuery(QUERY_DISTRICT_REPORTS_LIST, {
      variables: { districtIds: [currentDistrictId] },
    })
    const baseSchoolsGeneralReports = [
      {
        link: '/report/schoolreport',
        label: 'Schools'
      },
    ]
    const baseDistrictReports = [
      {
        link: '/report/meetingsreport',
        label: 'Meetings'
      },
      {
        link: '/report/districtreport',
        label: 'District'
      },
      {
        link: '/report/istarreport',
        label: 'IStar'
      }
    ]
    const baseSuperAdminReports = [
      {
        link: '/report/distrcitsummaryreport',
        label: 'District Summary'
      },
      {
        link: '/report/districtusagereport',
        label: 'District Usage'
      }
    ]

    const sharedSchoolsGeneralReports = [
      {
        districtReportId: '650db18082f763f0fa56304c',
        link: '/report/schoolreports',  //If we don't want districtReportId in url then use this.  Otherwise use next line and change Routes.
        //link: '/report/schoolreports/650db18082f763f0fa56304c',  
        label: 'School Metrics - Gender Distribution'
      },
      {
        districtReportId: '650daab782f763f0fa56304a',
        link: '/report/schoolreports',  //If we don't want districtReportId in url then use this.  Otherwise use next line and change Routes.
        //link: '/report/schoolreports/650daab782f763f0fa56304a',
        label: 'School Metrics - Ethnicity Distribution'
      }
    ]
    const sharedDistrictReports = [
      {
        districtReportId: '',
        link: '/report/meetingsreports',
        label: 'Meetings'
      },
      {
        districtReportId: '',
        link: '/report/districtreports',
        label: 'District'
      },
      {
        link: '/report/istarreports',
        label: 'IStar'
      }
    ]
    const sharedSuperAdminReports = [
      {
        districtReportId: '',
        link: '/report/distrcitsummaryreports',
        label: 'District Summary'
      },
      {
        districtReportId: '',
        link: '/report/districtusagereports',
        label: 'District Usage'
      }
    ]

    const mySchoolsGeneralReports = [
      {
        link: '/report/schoolreports/652805b9b4406f28ee2c4f66s',  //this is a districtReportId.  Will be loaded from query.
        label: 'User(Fran) School Metrics - Gender Distribution'
      }
    ]
    const myDistrictReports = [
      {
        link: '/report/meetingsreportu',
        label: 'Meetings'
      },
      {
        link: '/report/districtreportu',
        label: 'District'
      },
      {
        link: '/report/istarreportu',
        label: 'IStar'
      }
    ]
    const mySuperAdminReports = [
      {
        link: '/report/distrcitsummaryreportu',
        label: 'District Summaru'
      },
      {
        link: '/report/districtusagereportu',
        label: 'District Usage'
      }
    ]

    const canViewSchoolReports = Auth.hasPermission('VIEW_SCHOOL_REPORTS')
    const canViewDistrictReports = Auth.hasPermission('VIEW_DISTRICT_REPORTS')
    const canViewSuperAdminReports = Auth.hasPermission('VIEW_SUPER_REPORTS')

    if (!canViewSchoolReports) {
      return <></>
    }

    let baseSchoolMenus = []
    baseSchoolMenus = baseSchoolsGeneralReports.map(({ districtReportId, link, label }) => (
      <Menu.Item key={districtReportId}>
        <Link to={link}>{label}</Link>
      </Menu.Item>
    ))

    let baseDistrictMenus = []
    if (canViewDistrictReports) {
      baseDistrictMenus = baseDistrictReports.map(({ link, label }) => (
        <Menu.Item key={link}>
          <Link to={link}>{label}</Link>
        </Menu.Item>
      ))
    }

    let baseSuperAdminMenus = []
    if (canViewSuperAdminReports) {
      baseSuperAdminMenus = baseSuperAdminReports.map(({ link, label }) => (
        <Menu.Item key={link}>
          <Link to={link}>{label}</Link>
        </Menu.Item>
      ))
    }

    let mySchoolMenus = []
    mySchoolMenus = mySchoolsGeneralReports.map(({ districtReportId, link, label }) => (
      //<Menu.Item key={districtReportId} onClick={handleReportsMenuClick}>
      <Menu.Item key={districtReportId}>
        <Link to={link} state={{ districtReportId: districtReportId }}>{label}</Link>
      </Menu.Item>
    ))

    let myDistrictMenus = []
    if (canViewDistrictReports) {
      myDistrictMenus = myDistrictReports.map(({ link, label }) => (
        <Menu.Item key={link}>
          <Link to={link}>{label}</Link>
        </Menu.Item>
      ))
    }

    let mySuperAdminMenus = []
    if (canViewSuperAdminReports) {
      mySuperAdminMenus = mySuperAdminReports.map(({ link, label }) => (
        <Menu.Item key={link}>
          <Link to={link}>{label}</Link>
        </Menu.Item>
      ))
    }

    let sharedSchoolMenus = []
    sharedSchoolMenus = sharedSchoolsGeneralReports.map(({ districtReportId, link, label }) => (
      <Menu.Item key={districtReportId}>
        <Link to={link} state={{ districtReportId: districtReportId }}>{label}</Link>
      </Menu.Item>
    ))

    let sharedDistrictMenus = []
    if (canViewDistrictReports) {
      sharedDistrictMenus = sharedDistrictReports.map(({ link, label }) => (
        <Menu.Item key={link}>
          <Link to={link}>{label}</Link>
        </Menu.Item>
      ))
    }

    let sharedSuperAdminMenus = []
    if (canViewSuperAdminReports) {
      sharedSuperAdminMenus = sharedSuperAdminReports.map(({ link, label }) => (
        <Menu.Item key={link}>
          <Link to={link}>{label}</Link>
        </Menu.Item>
      ))
    }

    // syntax for nested submenus
    // <Menu onClick={handleClick} style={{ width: 256 }} mode="vertical">
    //   <SubMenu key="sub2" title="Navigation Two">
    //     <SubMenu key="sub3" title="Submenu">
    //       <Menu.Item key="7">Option 7</Menu.Item>
    //       <Menu.Item key="8">Option 8</Menu.Item>
    //     </SubMenu>
    //   </SubMenu>
    // </Menu>

    return (
      <>
        {/* to be commented back in when it's functioning */}
        {/* <Menu.SubMenu key="sub2" title="Reports" icon={<FileOutlined />}>
          <Menu.SubMenu

            title='Base Reports'
            key='reportsSubmenu'
            style={{ background: 'transparent' }}
          >
            {baseSchoolMenus}
            {baseSuperAdminMenus}
            {baseDistrictMenus}
          </Menu.SubMenu>
          <Menu.SubMenu
            title='Shared Reports'
            key='sharedReportsSubmenu'
            style={{ background: 'transparent' }}
          >
            {sharedSchoolMenus}
            {sharedSuperAdminMenus}
            {sharedDistrictMenus}
          </Menu.SubMenu>
          <Menu.SubMenu
            title='My Reports'
            key='myReportsSubmenu'
            style={{ background: 'transparent' }}
          >
            {mySchoolMenus}
            {mySuperAdminMenus}
            {myDistrictMenus}
          </Menu.SubMenu>
        </Menu.SubMenu> */}
      </>
    )
  }

  //districtReports available to all users in the district
  const sharedReportsMenu = () => {
    //Get districtReports and build menu tree
    const { data: districtReportsList, loading, error } = useQuery(QUERY_DISTRICT_REPORTS_LIST, {
      variables: { districtIds: [currentDistrictId] },
    })
    //console.log('FP districtReportsList',districtReportsList, currentDistrictId,userId)


    const schoolsGeneralReports = [
      {
        districtReportId: '650db18082f763f0fa56304c',
        link: '/report/schoolreports',  //If we don't want districtReportId in url then use this.  Otherwise use next line and change Routes.
        //link: '/report/schoolreports/650db18082f763f0fa56304c',  
        label: 'School Metrics - Gender Distribution'
      },
      {
        districtReportId: '650daab782f763f0fa56304a',
        link: '/report/schoolreports',  //If we don't want districtReportId in url then use this.  Otherwise use next line and change Routes.
        //link: '/report/schoolreports/650daab782f763f0fa56304a',
        label: 'School Metrics - Ethnicity Distribution'
      }
    ]
    const districtReports = [
      {
        districtReportId: '',
        link: '/report/meetingsreports',
        label: 'Meetings'
      },
      {
        districtReportId: '',
        link: '/report/districtreports',
        label: 'District'
      },
      {
        link: '/report/istarreports',
        label: 'IStar'
      }
    ]
    const superAdminReports = [
      {
        districtReportId: '',
        link: '/report/distrcitsummaryreports',
        label: 'District Summary'
      },
      {
        districtReportId: '',
        link: '/report/districtusagereports',
        label: 'District Usage'
      }
    ]

    const canViewSchoolReports = Auth.hasPermission('VIEW_SCHOOL_REPORTS')
    const canViewDistrictReports = Auth.hasPermission('VIEW_DISTRICT_REPORTS')
    const canViewSuperAdminReports = Auth.hasPermission('VIEW_SUPER_REPORTS')

    if (!canViewSchoolReports) {
      return <></>
    }

    let schoolMenus = []
    schoolMenus = schoolsGeneralReports.map(({ districtReportId, link, label }) => (
      //<Menu.Item key={districtReportId} onClick={handleReportsMenuClick}>
      <Menu.Item key={districtReportId}>
        <Link to={link} state={{ districtReportId: districtReportId }}>{label}</Link>
      </Menu.Item>
    ))

    let districtMenus = []
    if (canViewDistrictReports) {
      districtMenus = districtReports.map(({ link, label }) => (
        <Menu.Item key={link}>
          <Link to={link}>{label}</Link>
        </Menu.Item>
      ))
    }

    let superAdminMenus = []
    if (canViewSuperAdminReports) {
      superAdminMenus = superAdminReports.map(({ link, label }) => (
        <Menu.Item key={link}>
          <Link to={link}>{label}</Link>
        </Menu.Item>
      ))
    }

    return (
      <>
        <Menu.SubMenu
          title='Shared Reports'
          key='sharedReportsSubmenu'
          icon={<FileOutlined />}
          style={{ background: 'transparent' }}
        >
          {schoolMenus}
          {superAdminMenus}
          {districtMenus}
        </Menu.SubMenu>
      </>
    )
  }

  //userDistrictReports available only to logged in user
  const myReportsMenu = () => {
    const { data: userDistrictReportsList, loading, error } = useQuery(QUERY_USER_DISTRICT_REPORTS_LIST, {
      variables: { userId: userId, districtIds: [currentDistrictId] },
    })
    //console.log('FP userDistrictReportsList',userDistrictReportsList, currentDistrictId,userId)

    const schoolsGeneralReports = [
      {
        link: '/report/schoolreports/652805b9b4406f28ee2c4f66s',  //this is a districtReportId.  Will be loaded from query.
        label: 'User(Fran) School Metrics - Gender Distribution'
      }
    ]
    const districtReports = [
      {
        link: '/report/meetingsreportu',
        label: 'Meetings'
      },
      {
        link: '/report/districtreportu',
        label: 'District'
      },
      {
        link: '/report/istarreportu',
        label: 'IStar'
      }
    ]
    const superAdminReports = [
      {
        link: '/report/distrcitsummaryreportu',
        label: 'District Summary'
      },
      {
        link: '/report/districtusagereportu',
        label: 'District Usage'
      }
    ]

    const canViewSchoolReports = Auth.hasPermission('VIEW_SCHOOL_REPORTS')
    const canViewDistrictReports = Auth.hasPermission('VIEW_DISTRICT_REPORTS')
    const canViewSuperAdminReports = Auth.hasPermission('VIEW_SUPER_REPORTS')

    if (!canViewSchoolReports) {
      return <></>
    }

    const schoolMenus = schoolsGeneralReports.map(({ link, label }) => (
      <Menu.Item key={link}>
        <Link to={link}>{label}</Link>
      </Menu.Item>
    ))

    let districtMenus = []
    if (canViewDistrictReports) {
      districtMenus = districtReports.map(({ link, label }) => (
        <Menu.Item key={link}>
          <Link to={link}>{label}</Link>
        </Menu.Item>
      ))
    }

    let superAdminMenus = []
    if (canViewSuperAdminReports) {
      superAdminMenus = superAdminReports.map(({ link, label }) => (
        <Menu.Item key={link}>
          <Link to={link}>{label}</Link>
        </Menu.Item>
      ))
    }

    return (
      <>
        <Menu.SubMenu
          title='My Reports'
          key='meReportsSubmenu'
          icon={<FileOutlined />}
          style={{ background: 'transparent' }}
        >
          {schoolMenus}
          {superAdminMenus}
          {districtMenus}
        </Menu.SubMenu>
      </>
    )
  }





  const canListDistricts = Auth.hasPermission('LIST_DISTRICTS')
  const canListSchools = Auth.hasPermission('LIST_SCHOOLS')
  const canListUsers = Auth.hasPermission('LIST_USERS')
  const canListStudents = Auth.hasPermission('LIST_STUDENTS')
  const canListGrades = Auth.hasPermission('LIST_GRADES')
  const canListLookups = Auth.hasPermission('LIST_LOOKUPS')
  const canListDistrictForms = Auth.hasPermission('LIST_DISTRICT_FORMS')
  const canListDocuments = Auth.hasPermission('LIST_DOCUMENTS')
  const canAddImports = Auth.hasPermission('ADD_IMPORTS')
  const isSuperAdmin = Auth.hasRole('SUPER_ADMIN')

  return (
    <>
      <div className='appMenu'>
        <Menu
          theme={theme}
          defaultSelectedKeys={['/']}
          mode={mode}
          selectedKeys={[location.pathname]}
          style={{ background: 'transparent' }}
        >
          <Menu.Item key='/' icon={<DesktopOutlined />}>
            <Link to='/'>Dashboard</Link>
          </Menu.Item>

          {canListDistricts && (
            <>
              <Menu.Item key='/districts' icon={<FileOutlined />}>
                <Link to='/districts'>Districts</Link>
              </Menu.Item>
            </>
          )}

          {canListSchools && (
            <>
              <Menu.Item key='/schools' icon={<FileOutlined />}>
                <Link to='/schools'>Schools</Link>
              </Menu.Item>
            </>
          )}

          {canListUsers && (
            <>
              <Menu.Item key='/users' icon={<FileOutlined />}>
                <Link to='/users'>Users</Link>
              </Menu.Item>
            </>
          )}

          {canListStudents && (
            <>
              <Menu.Item key='/students' icon={<UserOutlined />}>
                <Link to='/students'>Students</Link>
              </Menu.Item>
            </>
          )}

          {canListGrades && isSuperAdmin && (
            <>
              <Menu.Item key='/grades' icon={<FileOutlined />}>
                <Link to='/grades'>Grades</Link>
              </Menu.Item>
            </>
          )}

          {canListLookups && (
            <>
              <Menu.Item key='/lookups' icon={<TableOutlined />}>
                <Link to='/lookups'>Lookups</Link>
              </Menu.Item>
            </>
          )}

          {canListDistrictForms && (
            <>
              <Menu.Item key='/districtforms' icon={<FileOutlined />}>
                <Link to='/districtforms'>District forms</Link>
              </Menu.Item>
            </>
          )}
          {/* currently broken */}
          {/* {canListDocuments && (
            <>
              <Menu.Item key='/documents' icon={<FileOutlined />}>
                <Link to='/documents'>Documents</Link>
              </Menu.Item>
            </>
          )} */}

          {/* {reportsMenu()} */}
          {/* {sharedReportsMenu()} */}
          {/* {myReportsMenu()} */}

          {canAddImports && (
            <>
              <Menu.Item key='/imports/add' icon={<FileOutlined />}>
                <Link to='/imports/add'>Import</Link>
              </Menu.Item>
            </>
          )}
          <Menu.Item key='/new' >
            <Link to='/new'>What's New</Link>
          </Menu.Item>
        </Menu>
      </div>
    </>
  )
}

SideMenu.propTypes = {
  mode: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired
}

export default SideMenu
