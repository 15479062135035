import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, List, Row, Col, Collapse, Card, Input, Space } from 'antd'
import { useParams } from 'react-router-dom'

import { IepFormContext } from '../../../utils/iepFormProvider'
import { PlusSquareOutlined, ArrowRightOutlined } from '@ant-design/icons'

// apollo & operations
import { useMutation, useQuery } from '@apollo/client'
import { QUERY_STUDENT_MEETING_DISTRICT_FORMS } from '../../../operations/queries/meeting'
import { QUERY_STUDENT_MEETING_FORMS } from '../../../operations/queries/meeting'
import { ADD_FORMS_TO_MEETING } from '../../../operations/mutations/meetings'
import FileUploadModal from '../../elements/FileUploadModal'
import {
  notificationError,
  notificationInfo,
  notificationSuccess
} from '../../../utils/notification'

const StudentFormSidebarEdit = ({
  setEditMode,
  setEditMeeting,
  editMeeting
}) => {
  const { Panel } = Collapse

  const { studentMeetingId } = useParams()
  const { setStudentFormInfo, setStudentMeetingId } = useContext(IepFormContext)
  const { data: meetingData, loading: loadingMeeting } = useQuery(
    QUERY_STUDENT_MEETING_FORMS,
    {
      variables: { id: studentMeetingId },
      notifyOnNetworkStatusChange: true
    }
  )
  const { data, loading } = useQuery(QUERY_STUDENT_MEETING_DISTRICT_FORMS, {
    variables: { id: studentMeetingId },
    notifyOnNetworkStatusChange: true
  })

  const [districtFormsList, setDistrictFormsList] = useState(
    data?.studentMeetingGeneral?.districtFormsList
  )
  const meetingTypeForms = []
  districtFormsList?.forEach(form => {
    if (data.studentMeetingGeneral.defaultFormIds.includes(form.formId)) {
      meetingTypeForms.push(form)
    }
  })
  const otherForms = []
  districtFormsList?.forEach(form => {
    if (form.formType.includes('Other Forms')) {
      otherForms.push(form)
    }
  })

  const iepForms = []
  districtFormsList?.forEach(form => {
    if (form.formType.includes('IEP Forms')) {
      iepForms.push(form)
    }
  })

  const noticeForms = []
  districtFormsList?.forEach(form => {
    if (form.formType.includes('Notice and Consent Forms')) {
      noticeForms.push(form)
    }
  })

  const evaluationForms = []
  districtFormsList?.forEach(form => {
    if (form.formType.includes('Evaluation Forms')) {
      evaluationForms.push(form)
    }
  })

  const eligibilityForms = []
  districtFormsList?.forEach(form => {
    if (form.formType.includes('Eligibility Forms')) {
      eligibilityForms.push(form)
    }
  })

  const notIep = []
  districtFormsList?.forEach(form => {
    if (form.formType.includes('504 Forms')) {
      notIep.push(form)
    }
  })

  const iapForms = []
  districtFormsList?.forEach(form => {
    if (form.formType.includes('CNG IAP Forms')) {
      iapForms.push(form)
    }
  })

  const healthPlanForms = []
  districtFormsList?.forEach(form => {
    if (form.formType.includes('Health Plan Forms')) {
      healthPlanForms.push(form)
    }
  })
  // Add / Remove Mutations
  const [addFormsToStudentMeeting, { loading: addFormLoading }] = useMutation(
    ADD_FORMS_TO_MEETING,
    {
      refetchQueries: [
        {
          query: QUERY_STUDENT_MEETING_DISTRICT_FORMS,
          variables: { id: studentMeetingId }
        },
        {
          query: QUERY_STUDENT_MEETING_FORMS,
          variables: { id: studentMeetingId }
        }
      ]
    }
  )

  // Set Student Info for IEP Provider
  useEffect(
    () => {
      if (data && !loading) {
        setStudentFormInfo({
          studentId: data?.studentMeetingGeneral.studentId,
          schoolId: data?.studentMeetingGeneral.schoolId
        })
        setStudentMeetingId(studentMeetingId)
        setDistrictFormsList(data?.studentMeetingGeneral?.districtFormsList)
      }
    },
    [data],
    [meetingData]
  )
  const formatFormName = name => {
    if (name) {
      return (
        name.toString().toLowerCase().charAt(0).toUpperCase() +
        name.toString().toLowerCase().slice(1)
      )
    }
  }

  const addDefaultForms = data => {
    const defaultFormCodes = data?.studentMeetingGeneral.defaultFormCodes
      ? data.studentMeetingGeneral.defaultFormCodes
      : []
    const districtFormsList = data?.studentMeetingGeneral.districtFormsList
      ? data?.studentMeetingGeneral.districtFormsList
      : []
    //Display notice and return if there are no default forms for the meeting type
    if (defaultFormCodes.length === 0) {
      notificationInfo(
        'There are no default forms defined for this meeting type.'
      )
      return
    }
    //Create searchable forms list from formType/forms districtFormsList structure
    const allDistrictForms = []
    districtFormsList.forEach(form => {
      allDistrictForms.push(form)
    })

    //Create a list form formCodes/formIds to add that meet the allowMultipleInstances policy
    const formCodesToAdd = []
    const formIdsToAdd = []
    for (
      let formCodeIndex = 0;
      formCodeIndex < defaultFormCodes.length;
      formCodeIndex++
    ) {
      const defaultFormCode = defaultFormCodes[formCodeIndex]
      const meetingForm = allDistrictForms.find(
        form => form.formCode === defaultFormCode
      )
      if (
        meetingForm.meetingFormInstances === 0 ||
        meetingForm.allowMultipleInstances === true
      ) {
        formCodesToAdd.push(meetingForm.formCode)
        formIdsToAdd.push(meetingForm.formId)
      }
    }

    //Add the default forms
    if (meetingData?.studentMeetingGeneral.studentFormIds.length <= 50) {
      if (formIdsToAdd.length > 0) {
        const studentMeeting = addFormsToStudentMeeting({
          variables: {
            studentMeetingId: studentMeetingId,
            formIdsToAdd: formIdsToAdd
          }
        })

        if (studentMeeting) {
          //Code here to update districtFormsList meetingFormInstances after we change to flat table return
          notificationSuccess(
            'Adding ' + formIdsToAdd.length + ' default forms to meeting',
            ''
          )
        }
      } else {
        notificationSuccess(
          'All default forms are already in this meeting.',
          ''
        )
      }
    } else {
      notificationError(
        'Meetings are limited to 50 forms. Please remove a form or create a new meeting.'
      )
    }
  }

  let locale = {
    emptyText:
      'This meeting type does not have any default forms. Please contact your administrator to add default forms.'
  }
  // hide no data as to not clutter search
  let localeSearch = {
    emptyText: ' ',
    // not sure empty icon does anything
    emptyIcon: ''
  }
  const Search = () => {
    // probably some redundancies I'm just happy it works
    const [allForms, setAllForms] = useState(districtFormsList)
    const [allFormNames, setAllFormNames] = useState([])
    const searchInput = []
    return (
      <>
        {' '}
        <Input.Search
          // on press enter works as intended but we can have different functionality if we want
          // onPressEnter
          // clear works as intended as well but we can have custom if we want
          // onClear={console.log('cleared')}
          onChange={value => {
            allForms?.filter(x => {
              // when cleared set to an empty array instead of displaying all forms
              if (value.target.value === '') {
                setAllFormNames([])
              } else {
                // if it's a part of search, don't ask about value.target.value, thanks antd?
                if (
                  x.name.includes(value.target.value) ||
                  x.name.toLowerCase().includes(value.target.value)
                ) {
                  // results of search
                  searchInput.push(x)
                }
                // set results in state
                setAllFormNames(searchInput)
              }
            })
          }}
          allowClear={true}
          // I mostly use this for the  icon, it is funtional though, does same as above
          onSearch={value => {
            allForms.filter(x => {
              if (value === '') {
                setAllFormNames([])
              } else {
                if (
                  x.name.includes(value) ||
                  x.name.toLowerCase().includes(value)
                ) {
                  searchInput.push(x)
                }
                setAllFormNames(searchInput)
              }
            })
          }}
        />
        <List
          itemLayout='horizontal'
          // loading={loading || addFormLoading}
          dataSource={allFormNames}
          locale={localeSearch}
          className='meetings-list'
          renderItem={item => (
            <List.Item
              style={{
                borderStyle: 'none',
                cursor: 'pointer'
              }}
              className='form-type-list-item'
              onClick={e => {
                e.stopPropagation()

                //Disable click if user don't have permission to add/remove form
                if (!item.userSubRoleFormPermission.add) {
                  notificationInfo(
                    'Not allowed!',
                    "Your sub-role doesn't have permission to add this form",
                    'bottomLeft'
                  )
                  return
                }
                //Disable click if form is a single instance form and it already exists in the meeting
                if (
                  item.meetingFormInstances > 0 &&
                  item.allowMultipleInstances === false
                ) {
                  notificationInfo(
                    'Form ' +
                    item.name +
                    ' already exists in the meeting and only one is allowed'
                  )
                  return
                }

                const formIdsToAdd = [item.formId]

                if (
                  meetingData?.studentMeetingGeneral.studentFormIds.length <= 50
                ) {
                  addFormsToStudentMeeting({
                    variables: {
                      studentMeetingId: studentMeetingId,
                      formIdsToAdd: formIdsToAdd
                    }
                  })
                  notificationSuccess(
                    'Adding form ' + item.name + ' to meeting',
                    ''
                  )
                  return
                } else {
                  notificationError(
                    'Meetings are limited to 50 forms. Please remove a form or create a new meeting.'
                  )
                }
              }}
            >
              <PlusSquareOutlined /> {formatFormName(item.name)}
            </List.Item>
          )}
        />
      </>
    )
  }

  return (
    <>
      {/* layout for 504 plan */}
      {data?.studentMeetingGeneral?.meetingType === '504 Plan' && (
        <>
          {' '}
          {/* <Card> */}
          <Row>
            <Col span={24} style={{ paddingBottom: '5px' }}>
              <FileUploadModal studentMeetingId={studentMeetingId} />
            </Col>
            <Col span={24} style={{ paddingBottom: '5px' }}>
              <Search />
            </Col>
          </Row>
          <>
            <Collapse
              defaultActiveKey={['1']}
              loading={loading || addFormLoading}
            >
              <Panel header='Default Forms' key='1'>
                <Row>
                  <Col span={24}>
                    <div style={{ fontWeight: 'bold', paddingBottom: '10px' }}>
                      Meeting Type: {data?.studentMeetingGeneral.meetingType}
                    </div>
                  </Col>
                  <Col span={24}>
                    <div>
                      <Button
                        size='small'
                        type='dashed'
                        //TODO: disable button if defaultFormIds.length===0
                        onClick={() => {
                          addDefaultForms(data)
                        }}
                      >
                        Add Default Forms
                        <PlusSquareOutlined />
                      </Button>
                    </div>
                  </Col>
                </Row>

                <List
                  itemLayout='horizontal'
                  loading={loading || addFormLoading}
                  dataSource={meetingTypeForms}
                  className='meetings-list'
                  locale={locale}
                  renderItem={item => (
                    <List.Item
                      style={{
                        borderStyle: 'none',
                        cursor: 'pointer'
                      }}
                      className='form-type-list-item'
                      onClick={e => {
                        e.stopPropagation()

                        //Disable click if user don't have permission to add/remove form
                        if (!item.userSubRoleFormPermission.add) {
                          notificationInfo(
                            'Not allowed!',
                            "Your sub-role doesn't have permission to add this form",
                            'bottomLeft'
                          )
                          return
                        }
                        //Disable click if form is a single instance form and it already exists in the meeting
                        if (
                          item.meetingFormInstances > 0 &&
                          item.allowMultipleInstances === false
                        ) {
                          notificationInfo(
                            'Form ' +
                            item.name +
                            ' already exists in the meeting and only one is allowed'
                          )
                          return
                        }

                        const formIdsToAdd = [item.formId]
                        if (
                          meetingData?.studentMeetingGeneral.studentFormIds
                            .length <= 50
                        ) {
                          addFormsToStudentMeeting({
                            variables: {
                              studentMeetingId: studentMeetingId,
                              formIdsToAdd: formIdsToAdd
                            }
                          })
                          notificationSuccess(
                            'Adding form ' + item.name + ' to meeting',
                            ''
                          )

                          return
                        } else {
                          notificationError(
                            'Meetings are limited to 50 forms. Please remove a form or create a new meeting.'
                          )
                        }
                      }}
                    >
                      <PlusSquareOutlined />{' '}
                      {formatFormName(item.name) ===
                        'Individualized education program (conference summary report)' ? (
                        <>Conference Summary Report</>
                      ) : (
                        <>{formatFormName(item.name)}</>
                      )}
                    </List.Item>
                  )}
                />
              </Panel>
              <Panel header='504 Forms' key='2'>
                <List
                  itemLayout='horizontal'
                  loading={loading || addFormLoading}
                  dataSource={notIep}
                  className='meetings-list'
                  renderItem={item => (
                    <List.Item
                      style={{
                        borderStyle: 'none',
                        cursor: 'pointer'
                      }}
                      className='form-type-list-item'
                      onClick={e => {
                        e.stopPropagation()

                        //Disable click if user don't have permission to add/remove form
                        if (!item.userSubRoleFormPermission.add) {
                          notificationInfo(
                            'Not allowed!',
                            "Your sub-role doesn't have permission to add this form",
                            'bottomLeft'
                          )
                          return
                        }
                        //Disable click if form is a single instance form and it already exists in the meeting
                        if (
                          item.meetingFormInstances > 0 &&
                          item.allowMultipleInstances === false
                        ) {
                          notificationInfo(
                            'Form ' +
                            item.name +
                            ' already exists in the meeting and only one is allowed'
                          )
                          return
                        }

                        const formIdsToAdd = [item.formId]
                        if (
                          meetingData?.studentMeetingGeneral.studentFormIds
                            .length <= 50
                        ) {
                          addFormsToStudentMeeting({
                            variables: {
                              studentMeetingId: studentMeetingId,
                              formIdsToAdd: formIdsToAdd
                            }
                          })
                          notificationSuccess(
                            'Adding form ' + item.name + ' to meeting',
                            ''
                          )

                          return
                        } else {
                          notificationError(
                            'Meetings are limited to 50 forms. Please remove a form or create a new meeting.'
                          )
                        }
                      }}
                    >
                      <PlusSquareOutlined /> {formatFormName(item.name)}
                    </List.Item>
                  )}
                />
              </Panel>

              <Panel header='Other Forms' key='3'>
                <List
                  itemLayout='horizontal'
                  loading={loading || addFormLoading}
                  dataSource={otherForms}
                  className='meetings-list'
                  renderItem={item => (
                    <List.Item
                      style={{
                        borderStyle: 'none',
                        cursor: 'pointer'
                      }}
                      className='form-type-list-item'
                      onClick={e => {
                        e.stopPropagation()

                        //Disable click if user don't have permission to add/remove form
                        if (!item.userSubRoleFormPermission.add) {
                          (
                            'Not allowed!',
                            "Your sub-role doesn't have permission to add this form",
                            'bottomLeft'
                          )
                          return
                        }
                        //Disable click if form is a single instance form and it already exists in the meeting
                        if (
                          item.meetingFormInstances > 0 &&
                          item.allowMultipleInstances === false
                        ) {
                          notificationInfo(
                            'Form ' +
                            item.name +
                            ' already exists in the meeting and only one is allowed'
                          )
                          return
                        }

                        const formIdsToAdd = [item.formId]
                        if (
                          meetingData?.studentMeetingGeneral.studentFormIds
                            .length <= 50
                        ) {
                          addFormsToStudentMeeting({
                            variables: {
                              studentMeetingId: studentMeetingId,
                              formIdsToAdd: formIdsToAdd
                            }
                          })
                          notificationSuccess(
                            'Adding form ' + item.name + ' to meeting',
                            ''
                          )

                          return
                        } else {
                          notificationError(
                            'Meetings are limited to 50 forms. Please remove a form or create a new meeting.'
                          )
                        }
                      }}
                    >
                      <PlusSquareOutlined /> {formatFormName(item.name)}
                    </List.Item>
                  )}
                />
              </Panel>
            </Collapse>
          </>
          {/* </Card> */}
        </>
      )}
      {/* layout for iep meeting type */}
      {data?.studentMeetingGeneral?.meetingType === 'Annual Review' ||
        data?.studentMeetingGeneral?.meetingType ===
        'Annual Review/Reevaluation' ||
        data?.studentMeetingGeneral?.meetingType === 'Change of Placement' ||
        data?.studentMeetingGeneral?.meetingType === 'Domains Meeting' ||
        data?.studentMeetingGeneral?.meetingType === 'Graduation' ||
        data?.studentMeetingGeneral?.meetingType === 'IEP Review/Revision' ||
        data?.studentMeetingGeneral?.meetingType === 'Initial Evaluation' ||
        data?.studentMeetingGeneral?.meetingType === 'Initial IEP' ||
        data?.studentMeetingGeneral?.meetingType ===
        'Manifestation Determination' ||
        data?.studentMeetingGeneral?.meetingType === 'Reevaluation' ? (
        <>
          {' '}
          {/* <Card> */}
          <Row>
            <Col span={24} style={{ paddingBottom: '5px' }}>
              <FileUploadModal studentMeetingId={studentMeetingId} />
            </Col>
            <Col span={24} style={{ paddingBottom: '5px' }}>
              <Search />
            </Col>
          </Row>
          <Collapse defaultActiveKey={['1']}>
            <Panel header='Default Forms' key='1'>
              <Row>
                <Col span={24}>
                  <div style={{ fontWeight: 'bold', paddingBottom: '10px' }}>
                    Meeting Type: {data?.studentMeetingGeneral.meetingType}
                  </div>
                </Col>
                <Col span={24}>
                  <div>
                    <Button
                      size='small'
                      type='dashed'
                      //TODO: disable button if defaultFormIds.length===0
                      onClick={() => {
                        addDefaultForms(data)
                      }}
                    >
                      Add Default Forms
                      <PlusSquareOutlined />
                    </Button>
                  </div>
                </Col>
              </Row>

              <List
                itemLayout='horizontal'
                loading={loading || addFormLoading}
                dataSource={meetingTypeForms}
                className='meetings-list'
                locale={locale}
                renderItem={item => (
                  <List.Item
                    style={{
                      borderStyle: 'none',
                      cursor: 'pointer'
                    }}
                    className='form-type-list-item'
                    onClick={e => {
                      e.stopPropagation()

                      //Disable click if user don't have permission to add/remove form
                      if (!item.userSubRoleFormPermission.add) {
                        notificationInfo(
                          'Not allowed!',
                          "Your sub-role doesn't have permission to add this form",
                          'bottomLeft'
                        )
                        return
                      }
                      //Disable click if form is a single instance form and it already exists in the meeting
                      if (
                        item.meetingFormInstances > 0 &&
                        item.allowMultipleInstances === false
                      ) {
                        notificationInfo(
                          'Form ' +
                          item.name +
                          ' already exists in the meeting and only one is allowed'
                        )
                        return
                      }

                      const formIdsToAdd = [item.formId]
                      if (
                        meetingData?.studentMeetingGeneral.studentFormIds
                          .length <= 50
                      ) {
                        addFormsToStudentMeeting({
                          variables: {
                            studentMeetingId: studentMeetingId,
                            formIdsToAdd: formIdsToAdd
                          }
                        })
                        notificationSuccess(
                          'Adding form ' + item.name + ' to meeting',
                          ''
                        )

                        return
                      } else {
                        notificationError(
                          'Meetings are limited to 50 forms. Please remove a form or create a new meeting.'
                        )
                      }
                    }}
                  >
                    <PlusSquareOutlined /> {formatFormName(item.name)}
                  </List.Item>
                )}
              />
            </Panel>
            <Panel header='Notice and Consent Forms' key='2'>
              <List
                itemLayout='horizontal'
                loading={loading || addFormLoading}
                dataSource={noticeForms}
                className='meetings-list'
                renderItem={item => (
                  <List.Item
                    style={{
                      borderStyle: 'none',
                      cursor: 'pointer'
                    }}
                    className='form-type-list-item'
                    onClick={e => {
                      e.stopPropagation()

                      //Disable click if user don't have permission to add/remove form
                      if (!item.userSubRoleFormPermission.add) {
                        notificationInfo(
                          'Not allowed!',
                          "Your sub-role doesn't have permission to add this form",
                          'bottomLeft'
                        )
                        return
                      }
                      //Disable click if form is a single instance form and it already exists in the meeting
                      if (
                        item.meetingFormInstances > 0 &&
                        item.allowMultipleInstances === false
                      ) {
                        notificationInfo(
                          'Form ' +
                          item.name +
                          ' already exists in the meeting and only one is allowed'
                        )
                        return
                      }

                      const formIdsToAdd = [item.formId]
                      if (
                        meetingData?.studentMeetingGeneral.studentFormIds
                          .length <= 50
                      ) {
                        addFormsToStudentMeeting({
                          variables: {
                            studentMeetingId: studentMeetingId,
                            formIdsToAdd: formIdsToAdd
                          }
                        })
                        notificationSuccess(
                          'Adding form ' + item.name + ' to meeting',
                          ''
                        )

                        return
                      } else {
                        notificationError(
                          'Meetings are limited to 50 forms. Please remove a form or create a new meeting.'
                        )
                      }
                    }}
                  >
                    <PlusSquareOutlined /> {formatFormName(item.name)}
                  </List.Item>
                )}
              />
            </Panel>
            <Panel header='IEP Forms' key='3'>
              <List
                itemLayout='horizontal'
                loading={loading || addFormLoading}
                dataSource={iepForms}
                className='meetings-list'
                renderItem={item => (
                  <List.Item
                    style={{
                      borderStyle: 'none',
                      cursor: 'pointer'
                    }}
                    className='form-type-list-item'
                    onClick={e => {
                      e.stopPropagation()

                      //Disable click if user don't have permission to add/remove form
                      if (!item.userSubRoleFormPermission.add) {
                        notificationInfo(
                          'Not allowed!',
                          "Your sub-role doesn't have permission to add this form",
                          'bottomLeft'
                        )
                        return
                      }
                      //Disable click if form is a single instance form and it already exists in the meeting
                      if (
                        item.meetingFormInstances > 0 &&
                        item.allowMultipleInstances === false
                      ) {
                        notificationInfo(
                          'Form ' +
                          item.name +
                          ' already exists in the meeting and only one is allowed'
                        )
                        return
                      }

                      const formIdsToAdd = [item.formId]
                      if (
                        meetingData?.studentMeetingGeneral.studentFormIds
                          .length <= 50
                      ) {
                        addFormsToStudentMeeting({
                          variables: {
                            studentMeetingId: studentMeetingId,
                            formIdsToAdd: formIdsToAdd
                          }
                        })
                        notificationSuccess(
                          'Adding form ' + item.name + ' to meeting',
                          ''
                        )

                        return
                      } else {
                        notificationError(
                          'Meetings are limited to 50 forms. Please remove a form or create a new meeting.'
                        )
                      }
                    }}
                  >
                    <PlusSquareOutlined /> {formatFormName(item.name)}
                  </List.Item>
                )}
              />
            </Panel>
            <Panel header='Evaluation Forms' key='4'>
              <List
                itemLayout='horizontal'
                loading={loading || addFormLoading}
                dataSource={evaluationForms}
                className='meetings-list'
                renderItem={item => (
                  <List.Item
                    style={{
                      borderStyle: 'none',
                      cursor: 'pointer'
                    }}
                    className='form-type-list-item'
                    onClick={e => {
                      e.stopPropagation()

                      //Disable click if user don't have permission to add/remove form
                      if (!item.userSubRoleFormPermission.add) {
                        notificationInfo(
                          'Not allowed!',
                          "Your sub-role doesn't have permission to add this form",
                          'bottomLeft'
                        )
                        return
                      }
                      //Disable click if form is a single instance form and it already exists in the meeting
                      if (
                        item.meetingFormInstances > 0 &&
                        item.allowMultipleInstances === false
                      ) {
                        notificationInfo(
                          'Form ' +
                          item.name +
                          ' already exists in the meeting and only one is allowed'
                        )
                        return
                      }

                      const formIdsToAdd = [item.formId]
                      if (
                        meetingData?.studentMeetingGeneral.studentFormIds
                          .length <= 50
                      ) {
                        addFormsToStudentMeeting({
                          variables: {
                            studentMeetingId: studentMeetingId,
                            formIdsToAdd: formIdsToAdd
                          }
                        })
                        notificationSuccess(
                          'Adding form ' + item.name + ' to meeting',
                          ''
                        )

                        return
                      } else {
                        notificationError(
                          'Meetings are limited to 50 forms. Please remove a form or create a new meeting.'
                        )
                      }
                    }}
                  >
                    <PlusSquareOutlined /> {formatFormName(item.name)}
                  </List.Item>
                )}
              />
            </Panel>
            <Panel header='Eligibility Forms' key='5'>
              <List
                itemLayout='horizontal'
                loading={loading || addFormLoading}
                dataSource={eligibilityForms}
                className='meetings-list'
                renderItem={item => (
                  <List.Item
                    style={{
                      borderStyle: 'none',
                      cursor: 'pointer'
                    }}
                    className='form-type-list-item'
                    onClick={e => {
                      e.stopPropagation()

                      //Disable click if user don't have permission to add/remove form
                      if (!item.userSubRoleFormPermission.add) {
                        notificationInfo(
                          'Not allowed!',
                          "Your sub-role doesn't have permission to add this form",
                          'bottomLeft'
                        )
                        return
                      }
                      //Disable click if form is a single instance form and it already exists in the meeting
                      if (
                        item.meetingFormInstances > 0 &&
                        item.allowMultipleInstances === false
                      ) {
                        notificationInfo(
                          'Form ' +
                          item.name +
                          ' already exists in the meeting and only one is allowed'
                        )
                        return
                      }

                      const formIdsToAdd = [item.formId]
                      if (
                        meetingData?.studentMeetingGeneral.studentFormIds
                          .length <= 50
                      ) {
                        addFormsToStudentMeeting({
                          variables: {
                            studentMeetingId: studentMeetingId,
                            formIdsToAdd: formIdsToAdd
                          }
                        })
                        notificationSuccess(
                          'Adding form ' + item.name + ' to meeting',
                          ''
                        )

                        return
                      } else {
                        notificationError(
                          'Meetings are limited to 50 forms. Please remove a form or create a new meeting.'
                        )
                      }
                    }}
                  >
                    <PlusSquareOutlined /> {formatFormName(item.name)}
                  </List.Item>
                )}
              />
            </Panel>
            <Panel header='Other Forms' key='6'>
              <List
                itemLayout='horizontal'
                loading={loading || addFormLoading}
                dataSource={otherForms}
                className='meetings-list'
                renderItem={item => (
                  <List.Item
                    style={{
                      borderStyle: 'none',
                      cursor: 'pointer'
                    }}
                    className='form-type-list-item'
                    onClick={e => {
                      e.stopPropagation()

                      //Disable click if user don't have permission to add/remove form
                      if (!item.userSubRoleFormPermission.add) {
                        notificationInfo(
                          'Not allowed!',
                          "Your sub-role doesn't have permission to add this form",
                          'bottomLeft'
                        )
                        return
                      }
                      //Disable click if form is a single instance form and it already exists in the meeting
                      if (
                        item.meetingFormInstances > 0 &&
                        item.allowMultipleInstances === false
                      ) {
                        notificationInfo(
                          'Form ' +
                          item.name +
                          ' already exists in the meeting and only one is allowed'
                        )
                        return
                      }

                      const formIdsToAdd = [item.formId]
                      if (
                        meetingData?.studentMeetingGeneral.studentFormIds
                          .length <= 50
                      ) {
                        addFormsToStudentMeeting({
                          variables: {
                            studentMeetingId: studentMeetingId,
                            formIdsToAdd: formIdsToAdd
                          }
                        })
                        notificationSuccess(
                          'Adding form ' + item.name + ' to meeting',
                          ''
                        )

                        return
                      } else {
                        notificationError(
                          'Meetings are limited to 50 forms. Please remove a form or create a new meeting.'
                        )
                      }
                    }}
                  >
                    <PlusSquareOutlined /> {formatFormName(item.name)}
                  </List.Item>
                )}
              />
            </Panel>
          </Collapse>
          {/* </Card> */}
        </>
      ) : (
        <></>
      )}
      {/* layout for custom meeting type */}
      {data?.studentMeetingGeneral?.meetingType != '504 Plan' &&
        data?.studentMeetingGeneral?.meetingType != 'Annual Review' &&
        data?.studentMeetingGeneral?.meetingType !=
        'Annual Review/Reevaluation' &&
        data?.studentMeetingGeneral?.meetingType != 'IAP Plan' &&
        data?.studentMeetingGeneral?.meetingType != 'Change of Placement' &&
        data?.studentMeetingGeneral?.meetingType != 'Domains Meeting' &&
        data?.studentMeetingGeneral?.meetingType != 'Graduation' &&
        data?.studentMeetingGeneral?.meetingType != 'IEP Review/Revision' &&
        data?.studentMeetingGeneral?.meetingType != 'Initial Evaluation' &&
        data?.studentMeetingGeneral?.meetingType != 'Initial IEP' &&
        data?.studentMeetingGeneral?.meetingType != 'Health Plan' &&
        data?.studentMeetingGeneral?.meetingType !=
        'Manifestation Determination' &&
        data?.studentMeetingGeneral?.meetingType != 'Reevaluation' ? (
        <>
          {' '}
          {/* <Card> */}
          <Row>
            <Col span={24} style={{ paddingBottom: '5px' }}>
              <FileUploadModal studentMeetingId={studentMeetingId} />
            </Col>
            <Col span={24} style={{ paddingBottom: '5px' }}>
              <Search />
            </Col>
          </Row>
          <Collapse defaultActiveKey={['1']}>
            <Panel header='Default Forms' key='1'>
              <Row>
                <Col span={24}>
                  <div style={{ fontWeight: 'bold', paddingBottom: '10px' }}>
                    Meeting Type: {data?.studentMeetingGeneral.meetingType}
                  </div>
                </Col>
                <Col span={24}>
                  <div>
                    <Button
                      size='small'
                      type='dashed'
                      //TODO: disable button if defaultFormIds.length===0
                      onClick={() => {
                        addDefaultForms(data)
                      }}
                    >
                      Add Default Forms
                      <PlusSquareOutlined />
                    </Button>
                  </div>
                </Col>
              </Row>

              <List
                itemLayout='horizontal'
                loading={loading || addFormLoading}
                dataSource={meetingTypeForms}
                className='meetings-list'
                locale={locale}
                renderItem={item => (
                  <List.Item
                    style={{
                      borderStyle: 'none',
                      cursor: 'pointer'
                    }}
                    className='form-type-list-item'
                    onClick={e => {
                      e.stopPropagation()

                      //Disable click if user don't have permission to add/remove form
                      if (!item.userSubRoleFormPermission.add) {
                        notificationInfo(
                          'Not allowed!',
                          "Your sub-role doesn't have permission to add this form",
                          'bottomLeft'
                        )
                        return
                      }
                      //Disable click if form is a single instance form and it already exists in the meeting
                      if (
                        item.meetingFormInstances > 0 &&
                        item.allowMultipleInstances === false
                      ) {
                        notificationInfo(
                          'Form ' +
                          item.name +
                          ' already exists in the meeting and only one is allowed'
                        )
                        return
                      }

                      const formIdsToAdd = [item.formId]
                      if (
                        meetingData?.studentMeetingGeneral.studentFormIds
                          .length <= 50
                      ) {
                        addFormsToStudentMeeting({
                          variables: {
                            studentMeetingId: studentMeetingId,
                            formIdsToAdd: formIdsToAdd
                          }
                        })
                        notificationSuccess(
                          'Adding form ' + item.name + ' to meeting',
                          ''
                        )

                        return
                      } else {
                        notificationError(
                          'Meetings are limited to 50 forms. Please remove a form or create a new meeting.'
                        )
                      }
                    }}
                  >
                    <PlusSquareOutlined /> {formatFormName(item.name)}
                  </List.Item>
                )}
              />
            </Panel>
            <Panel header='Notice and Consent Forms' key='2'>
              <List
                itemLayout='horizontal'
                loading={loading || addFormLoading}
                dataSource={noticeForms}
                className='meetings-list'
                renderItem={item => (
                  <List.Item
                    style={{
                      borderStyle: 'none',
                      cursor: 'pointer'
                    }}
                    className='form-type-list-item'
                    onClick={e => {
                      e.stopPropagation()

                      //Disable click if user don't have permission to add/remove form
                      if (!item.userSubRoleFormPermission.add) {
                        notificationInfo(
                          'Not allowed!',
                          "Your sub-role doesn't have permission to add this form",
                          'bottomLeft'
                        )
                        return
                      }
                      //Disable click if form is a single instance form and it already exists in the meeting
                      if (
                        item.meetingFormInstances > 0 &&
                        item.allowMultipleInstances === false
                      ) {
                        notificationInfo(
                          'Form ' +
                          item.name +
                          ' already exists in the meeting and only one is allowed'
                        )
                        return
                      }

                      const formIdsToAdd = [item.formId]
                      if (
                        meetingData?.studentMeetingGeneral.studentFormIds
                          .length <= 50
                      ) {
                        addFormsToStudentMeeting({
                          variables: {
                            studentMeetingId: studentMeetingId,
                            formIdsToAdd: formIdsToAdd
                          }
                        })
                        notificationSuccess(
                          'Adding form ' + item.name + ' to meeting',
                          ''
                        )

                        return
                      } else {
                        notificationError(
                          'Meetings are limited to 50 forms. Please remove a form or create a new meeting.'
                        )
                      }
                    }}
                  >
                    <PlusSquareOutlined /> {formatFormName(item.name)}
                  </List.Item>
                )}
              />
            </Panel>
            <Panel header='IEP Forms' key='3'>
              <List
                itemLayout='horizontal'
                loading={loading || addFormLoading}
                dataSource={iepForms}
                className='meetings-list'
                renderItem={item => (
                  <List.Item
                    style={{
                      borderStyle: 'none',
                      cursor: 'pointer'
                    }}
                    className='form-type-list-item'
                    onClick={e => {
                      e.stopPropagation()

                      //Disable click if user don't have permission to add/remove form
                      if (!item.userSubRoleFormPermission.add) {
                        notificationInfo(
                          'Not allowed!',
                          "Your sub-role doesn't have permission to add this form",
                          'bottomLeft'
                        )
                        return
                      }
                      //Disable click if form is a single instance form and it already exists in the meeting
                      if (
                        item.meetingFormInstances > 0 &&
                        item.allowMultipleInstances === false
                      ) {
                        notificationInfo(
                          'Form ' +
                          item.name +
                          ' already exists in the meeting and only one is allowed'
                        )
                        return
                      }

                      const formIdsToAdd = [item.formId]
                      if (
                        meetingData?.studentMeetingGeneral.studentFormIds
                          .length <= 50
                      ) {
                        addFormsToStudentMeeting({
                          variables: {
                            studentMeetingId: studentMeetingId,
                            formIdsToAdd: formIdsToAdd
                          }
                        })
                        notificationSuccess(
                          'Adding form ' + item.name + ' to meeting',
                          ''
                        )

                        return
                      } else {
                        notificationError(
                          'Meetings are limited to 50 forms. Please remove a form or create a new meeting.'
                        )
                      }
                    }}
                  >
                    <PlusSquareOutlined /> {formatFormName(item.name)}
                  </List.Item>
                )}
              />
            </Panel>
            <Panel header='Evaluation Forms' key='4'>
              <List
                itemLayout='horizontal'
                loading={loading || addFormLoading}
                dataSource={evaluationForms}
                className='meetings-list'
                renderItem={item => (
                  <List.Item
                    style={{
                      borderStyle: 'none',
                      cursor: 'pointer'
                    }}
                    className='form-type-list-item'
                    onClick={e => {
                      e.stopPropagation()

                      //Disable click if user don't have permission to add/remove form
                      if (!item.userSubRoleFormPermission.add) {
                        notificationInfo(
                          'Not allowed!',
                          "Your sub-role doesn't have permission to add this form",
                          'bottomLeft'
                        )
                        return
                      }
                      //Disable click if form is a single instance form and it already exists in the meeting
                      if (
                        item.meetingFormInstances > 0 &&
                        item.allowMultipleInstances === false
                      ) {
                        notificationInfo(
                          'Form ' +
                          item.name +
                          ' already exists in the meeting and only one is allowed'
                        )
                        return
                      }

                      const formIdsToAdd = [item.formId]
                      if (
                        meetingData?.studentMeetingGeneral.studentFormIds
                          .length <= 50
                      ) {
                        addFormsToStudentMeeting({
                          variables: {
                            studentMeetingId: studentMeetingId,
                            formIdsToAdd: formIdsToAdd
                          }
                        })
                        notificationSuccess(
                          'Adding form ' + item.name + ' to meeting',
                          ''
                        )

                        return
                      } else {
                        notificationError(
                          'Meetings are limited to 50 forms. Please remove a form or create a new meeting.'
                        )
                      }
                    }}
                  >
                    <PlusSquareOutlined /> {formatFormName(item.name)}
                  </List.Item>
                )}
              />
            </Panel>
            <Panel header='Eligibility Forms' key='5'>
              <List
                itemLayout='horizontal'
                loading={loading || addFormLoading}
                dataSource={eligibilityForms}
                className='meetings-list'
                renderItem={item => (
                  <List.Item
                    style={{
                      borderStyle: 'none',
                      cursor: 'pointer'
                    }}
                    className='form-type-list-item'
                    onClick={e => {
                      e.stopPropagation()

                      //Disable click if user don't have permission to add/remove form
                      if (!item.userSubRoleFormPermission.add) {
                        notificationInfo(
                          'Not allowed!',
                          "Your sub-role doesn't have permission to add this form",
                          'bottomLeft'
                        )
                        return
                      }
                      //Disable click if form is a single instance form and it already exists in the meeting
                      if (
                        item.meetingFormInstances > 0 &&
                        item.allowMultipleInstances === false
                      ) {
                        notificationInfo(
                          'Form ' +
                          item.name +
                          ' already exists in the meeting and only one is allowed'
                        )
                        return
                      }

                      const formIdsToAdd = [item.formId]
                      if (
                        meetingData?.studentMeetingGeneral.studentFormIds
                          .length <= 50
                      ) {
                        addFormsToStudentMeeting({
                          variables: {
                            studentMeetingId: studentMeetingId,
                            formIdsToAdd: formIdsToAdd
                          }
                        })
                        notificationSuccess(
                          'Adding form ' + item.name + ' to meeting',
                          ''
                        )

                        return
                      } else {
                        notificationError(
                          'Meetings are limited to 50 forms. Please remove a form or create a new meeting.'
                        )
                      }
                    }}
                  >
                    <PlusSquareOutlined /> {formatFormName(item.name)}
                  </List.Item>
                )}
              />
            </Panel>
            <Panel header='504 Forms' key='29999'>
              <List
                itemLayout='horizontal'
                loading={loading || addFormLoading}
                dataSource={notIep}
                className='meetings-list'
                renderItem={item => (
                  <List.Item
                    style={{
                      borderStyle: 'none',
                      cursor: 'pointer'
                    }}
                    className='form-type-list-item'
                    onClick={e => {
                      e.stopPropagation()

                      //Disable click if user don't have permission to add/remove form
                      if (!item.userSubRoleFormPermission.add) {
                        notificationInfo(
                          'Not allowed!',
                          "Your sub-role doesn't have permission to add this form",
                          'bottomLeft'
                        )
                        return
                      }
                      //Disable click if form is a single instance form and it already exists in the meeting
                      if (
                        item.meetingFormInstances > 0 &&
                        item.allowMultipleInstances === false
                      ) {
                        notificationInfo(
                          'Form ' +
                          item.name +
                          ' already exists in the meeting and only one is allowed'
                        )
                        return
                      }

                      const formIdsToAdd = [item.formId]
                      if (
                        meetingData?.studentMeetingGeneral.studentFormIds
                          .length <= 50
                      ) {
                        addFormsToStudentMeeting({
                          variables: {
                            studentMeetingId: studentMeetingId,
                            formIdsToAdd: formIdsToAdd
                          }
                        })
                        notificationSuccess(
                          'Adding form ' + item.name + ' to meeting',
                          ''
                        )

                        return
                      } else {
                        notificationError(
                          'Meetings are limited to 50 forms. Please remove a form or create a new meeting.'
                        )
                      }
                    }}
                  >
                    <PlusSquareOutlined /> {formatFormName(item.name)}
                  </List.Item>
                )}
              />
            </Panel>
            <Panel header='Other Forms' key='6'>
              <List
                itemLayout='horizontal'
                loading={loading || addFormLoading}
                dataSource={otherForms}
                className='meetings-list'
                renderItem={item => (
                  <List.Item
                    style={{
                      borderStyle: 'none',
                      cursor: 'pointer'
                    }}
                    className='form-type-list-item'
                    onClick={e => {
                      e.stopPropagation()

                      //Disable click if user don't have permission to add/remove form
                      if (!item.userSubRoleFormPermission.add) {
                        notificationInfo(
                          'Not allowed!',
                          "Your sub-role doesn't have permission to add this form",
                          'bottomLeft'
                        )
                        return
                      }
                      //Disable click if form is a single instance form and it already exists in the meeting
                      if (
                        item.meetingFormInstances > 0 &&
                        item.allowMultipleInstances === false
                      ) {
                        notificationInfo(
                          'Form ' +
                          item.name +
                          ' already exists in the meeting and only one is allowed'
                        )
                        return
                      }

                      const formIdsToAdd = [item.formId]
                      if (
                        meetingData?.studentMeetingGeneral.studentFormIds
                          .length <= 50
                      ) {
                        addFormsToStudentMeeting({
                          variables: {
                            studentMeetingId: studentMeetingId,
                            formIdsToAdd: formIdsToAdd
                          }
                        })
                        notificationSuccess(
                          'Adding form ' + item.name + ' to meeting',
                          ''
                        )

                        return
                      } else {
                        notificationError(
                          'Meetings are limited to 50 forms. Please remove a form or create a new meeting.'
                        )
                      }
                    }}
                  >
                    <PlusSquareOutlined /> {formatFormName(item.name)}
                  </List.Item>
                )}
              />
            </Panel>
          </Collapse>
          {/* </Card> */}
        </>
      ) : (
        <></>
      )}
      {data?.studentMeetingGeneral?.meetingType === 'IAP Plan' ? (
        <>
          {' '}
          <Row>
            <Col span={24} style={{ paddingBottom: '5px' }}>
              <FileUploadModal studentMeetingId={studentMeetingId} />
            </Col>
            <Col span={24} style={{ paddingBottom: '5px' }}>
              <Search />
            </Col>
            <Collapse defaultActiveKey={['1']}>
              <Panel header='Default Forms' key='1'>
                <Row>
                  <Col span={24}>
                    <div style={{ fontWeight: 'bold', paddingBottom: '10px' }}>
                      Meeting Type: {data?.studentMeetingGeneral.meetingType}
                    </div>
                  </Col>
                  <Col span={24}>
                    <div>
                      <Button
                        size='small'
                        type='dashed'
                        //TODO: disable button if defaultFormIds.length===0
                        onClick={() => {
                          addDefaultForms(data)
                        }}
                      >
                        Add Default Forms
                        <PlusSquareOutlined />
                      </Button>
                    </div>
                  </Col>
                </Row>

                <List
                  itemLayout='horizontal'
                  loading={loading || addFormLoading}
                  dataSource={meetingTypeForms}
                  className='meetings-list'
                  locale={locale}
                  renderItem={item => (
                    <List.Item
                      style={{
                        borderStyle: 'none',
                        cursor: 'pointer'
                      }}
                      className='form-type-list-item'
                      onClick={e => {
                        e.stopPropagation()

                        //Disable click if user don't have permission to add/remove form
                        if (!item.userSubRoleFormPermission.add) {
                          notificationInfo(
                            'Not allowed!',
                            "Your sub-role doesn't have permission to add this form",
                            'bottomLeft'
                          )
                          return
                        }
                        //Disable click if form is a single instance form and it already exists in the meeting
                        if (
                          item.meetingFormInstances > 0 &&
                          item.allowMultipleInstances === false
                        ) {
                          notificationInfo(
                            'Form ' +
                            item.name +
                            ' already exists in the meeting and only one is allowed'
                          )
                          return
                        }

                        const formIdsToAdd = [item.formId]
                        if (
                          meetingData?.studentMeetingGeneral.studentFormIds
                            .length <= 50
                        ) {
                          addFormsToStudentMeeting({
                            variables: {
                              studentMeetingId: studentMeetingId,
                              formIdsToAdd: formIdsToAdd
                            }
                          })
                          notificationSuccess(
                            'Adding form ' + item.name + ' to meeting',
                            ''
                          )

                          return
                        } else {
                          notificationError(
                            'Meetings are limited to 50 forms. Please remove a form or create a new meeting.'
                          )
                        }
                      }}
                    >
                      <PlusSquareOutlined /> {formatFormName(item.name)}
                    </List.Item>
                  )}
                />
              </Panel>
              <Panel header='IAP Forms' key='2'>
                <List
                  itemLayout='horizontal'
                  loading={loading || addFormLoading}
                  dataSource={iapForms}
                  className='meetings-list'
                  renderItem={item => (
                    <List.Item
                      style={{
                        borderStyle: 'none',
                        cursor: 'pointer'
                      }}
                      className='form-type-list-item'
                      onClick={e => {
                        e.stopPropagation()

                        //Disable click if user don't have permission to add/remove form
                        if (!item.userSubRoleFormPermission.add) {
                          notificationInfo(
                            'Not allowed!',
                            "Your sub-role doesn't have permission to add this form",
                            'bottomLeft'
                          )
                          return
                        }
                        //Disable click if form is a single instance form and it already exists in the meeting
                        if (
                          item.meetingFormInstances > 0 &&
                          item.allowMultipleInstances === false
                        ) {
                          notificationInfo(
                            'Form ' +
                            item.name +
                            ' already exists in the meeting and only one is allowed'
                          )
                          return
                        }

                        const formIdsToAdd = [item.formId]
                        if (
                          meetingData?.studentMeetingGeneral.studentFormIds
                            .length <= 50
                        ) {
                          addFormsToStudentMeeting({
                            variables: {
                              studentMeetingId: studentMeetingId,
                              formIdsToAdd: formIdsToAdd
                            }
                          })
                          notificationSuccess(
                            'Adding form ' + item.name + ' to meeting',
                            ''
                          )

                          return
                        } else {
                          notificationError(
                            'Meetings are limited to 50 forms. Please remove a form or create a new meeting.'
                          )
                        }
                      }}
                    >
                      <PlusSquareOutlined /> {formatFormName(item.name)}
                    </List.Item>
                  )}
                />
              </Panel>
            </Collapse>
          </Row>
        </>
      ) : (
        <></>
      )}
      {data?.studentMeetingGeneral?.meetingType === 'Health Plan' ? <><Row>
        <Col span={24} style={{ paddingBottom: '5px' }}>
          <FileUploadModal studentMeetingId={studentMeetingId} />
        </Col>
        <Col span={24} style={{ paddingBottom: '5px' }}>
          <Search />
        </Col>
        <Collapse defaultActiveKey={['1']}>
          <Panel header='Default Forms' key='1'>
            <Row>
              <Col span={24}>
                <div style={{ fontWeight: 'bold', paddingBottom: '10px' }}>
                  Meeting Type: {data?.studentMeetingGeneral.meetingType}
                </div>
              </Col>
              <Col span={24}>
                <div>
                  <Button
                    size='small'
                    type='dashed'
                    //TODO: disable button if defaultFormIds.length===0
                    onClick={() => {
                      addDefaultForms(data)
                    }}
                  >
                    Add Default Forms
                    <PlusSquareOutlined />
                  </Button>
                </div>
              </Col>
            </Row>

            <List
              itemLayout='horizontal'
              loading={loading || addFormLoading}
              dataSource={meetingTypeForms}
              className='meetings-list'
              locale={locale}
              renderItem={item => (
                <List.Item
                  style={{
                    borderStyle: 'none',
                    cursor: 'pointer'
                  }}
                  className='form-type-list-item'
                  onClick={e => {
                    e.stopPropagation()

                    //Disable click if user don't have permission to add/remove form
                    if (!item.userSubRoleFormPermission.add) {
                      notificationInfo(
                        'Not allowed!',
                        "Your sub-role doesn't have permission to add this form",
                        'bottomLeft'
                      )
                      return
                    }
                    //Disable click if form is a single instance form and it already exists in the meeting
                    if (
                      item.meetingFormInstances > 0 &&
                      item.allowMultipleInstances === false
                    ) {
                      notificationInfo(
                        'Form ' +
                        item.name +
                        ' already exists in the meeting and only one is allowed'
                      )
                      return
                    }

                    const formIdsToAdd = [item.formId]
                    if (
                      meetingData?.studentMeetingGeneral.studentFormIds
                        .length <= 50
                    ) {
                      addFormsToStudentMeeting({
                        variables: {
                          studentMeetingId: studentMeetingId,
                          formIdsToAdd: formIdsToAdd
                        }
                      })
                      notificationSuccess(
                        'Adding form ' + item.name + ' to meeting',
                        ''
                      )

                      return
                    } else {
                      notificationError(
                        'Meetings are limited to 50 forms. Please remove a form or create a new meeting.'
                      )
                    }
                  }}
                >
                  <PlusSquareOutlined /> {formatFormName(item.name)}
                </List.Item>
              )}
            />
          </Panel>
          <Panel header='Health Plan Forms' key='2'>
            <List
              itemLayout='horizontal'
              loading={loading || addFormLoading}
              dataSource={healthPlanForms}
              className='meetings-list'
              renderItem={item => (
                <List.Item
                  style={{
                    borderStyle: 'none',
                    cursor: 'pointer'
                  }}
                  className='form-type-list-item'
                  onClick={e => {
                    e.stopPropagation()

                    //Disable click if user don't have permission to add/remove form
                    if (!item.userSubRoleFormPermission.add) {
                      notificationInfo(
                        'Not allowed!',
                        "Your sub-role doesn't have permission to add this form",
                        'bottomLeft'
                      )
                      return
                    }
                    //Disable click if form is a single instance form and it already exists in the meeting
                    if (
                      item.meetingFormInstances > 0 &&
                      item.allowMultipleInstances === false
                    ) {
                      notificationInfo(
                        'Form ' +
                        item.name +
                        ' already exists in the meeting and only one is allowed'
                      )
                      return
                    }

                    const formIdsToAdd = [item.formId]
                    if (
                      meetingData?.studentMeetingGeneral.studentFormIds
                        .length <= 50
                    ) {
                      addFormsToStudentMeeting({
                        variables: {
                          studentMeetingId: studentMeetingId,
                          formIdsToAdd: formIdsToAdd
                        }
                      })
                      notificationSuccess(
                        'Adding form ' + item.name + ' to meeting',
                        ''
                      )

                      return
                    } else {
                      notificationError(
                        'Meetings are limited to 50 forms. Please remove a form or create a new meeting.'
                      )
                    }
                  }}
                >
                  <PlusSquareOutlined /> {formatFormName(item.name)}
                </List.Item>
              )}
            />
          </Panel>

          <Panel header='Other Forms' key='3'>
            <List
              itemLayout='horizontal'
              loading={loading || addFormLoading}
              dataSource={otherForms}
              className='meetings-list'
              renderItem={item => (
                <List.Item
                  style={{
                    borderStyle: 'none',
                    cursor: 'pointer'
                  }}
                  className='form-type-list-item'
                  onClick={e => {
                    e.stopPropagation()

                    //Disable click if user don't have permission to add/remove form
                    if (!item.userSubRoleFormPermission.add) {
                      (
                        'Not allowed!',
                        "Your sub-role doesn't have permission to add this form",
                        'bottomLeft'
                      )
                      return
                    }
                    //Disable click if form is a single instance form and it already exists in the meeting
                    if (
                      item.meetingFormInstances > 0 &&
                      item.allowMultipleInstances === false
                    ) {
                      notificationInfo(
                        'Form ' +
                        item.name +
                        ' already exists in the meeting and only one is allowed'
                      )
                      return
                    }

                    const formIdsToAdd = [item.formId]
                    if (
                      meetingData?.studentMeetingGeneral.studentFormIds
                        .length <= 50
                    ) {
                      addFormsToStudentMeeting({
                        variables: {
                          studentMeetingId: studentMeetingId,
                          formIdsToAdd: formIdsToAdd
                        }
                      })
                      notificationSuccess(
                        'Adding form ' + item.name + ' to meeting',
                        ''
                      )

                      return
                    } else {
                      notificationError(
                        'Meetings are limited to 50 forms. Please remove a form or create a new meeting.'
                      )
                    }
                  }}
                >
                  <PlusSquareOutlined /> {formatFormName(item.name)}
                </List.Item>
              )}
            />
          </Panel>

        </Collapse>
      </Row>

      </> : <></>}
    </>
  )
}

StudentFormSidebarEdit.propTypes = {
  setEditMode: PropTypes.bool
}

export default StudentFormSidebarEdit
