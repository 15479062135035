/**
 * RBAC
 * ex: PERMISSION_NAME: ['ROLE1', 'ROLE2'] // user needs ROLE1 or ROLE2 to get access to PERMISSION_NAME
 */

const SUPER_ADMIN = "SUPER_ADMIN";
const DISTRICTS_ADMIN = "DISTRICTS_ADMIN";
const SCHOOLS_ADMIN = "SCHOOLS_ADMIN";
const SCHOOLS_GENERAL = "SCHOOLS_GENERAL";

const RBAC = {
  // district
  LIST_DISTRICTS: [SUPER_ADMIN, DISTRICTS_ADMIN],
  ADD_DISTRICTS: [SUPER_ADMIN],
  EDIT_DISTRICTS: [SUPER_ADMIN, DISTRICTS_ADMIN],

  // schools
  LIST_SCHOOLS: [SUPER_ADMIN, DISTRICTS_ADMIN, SCHOOLS_ADMIN],
  ADD_SCHOOLS: [SUPER_ADMIN, DISTRICTS_ADMIN],
  EDIT_SCHOOLS: [SUPER_ADMIN, DISTRICTS_ADMIN, SCHOOLS_ADMIN],

  // students
  LIST_STUDENTS: [SUPER_ADMIN, DISTRICTS_ADMIN, SCHOOLS_ADMIN, SCHOOLS_GENERAL],
  ADD_STUDENTS: [SUPER_ADMIN, DISTRICTS_ADMIN, SCHOOLS_ADMIN, SCHOOLS_GENERAL],
  EDIT_STUDENTS: [SUPER_ADMIN, DISTRICTS_ADMIN, SCHOOLS_ADMIN, SCHOOLS_GENERAL],

  // users
  LIST_USERS: [SUPER_ADMIN, DISTRICTS_ADMIN, SCHOOLS_ADMIN],
  ADD_USERS: [SUPER_ADMIN],
  EDIT_USERS: [SUPER_ADMIN, DISTRICTS_ADMIN, SCHOOLS_ADMIN],

  // grades
  LIST_GRADES: [SUPER_ADMIN, DISTRICTS_ADMIN],
  ADD_GRADES: [SUPER_ADMIN, DISTRICTS_ADMIN],
  EDIT_GRADES: [SUPER_ADMIN, DISTRICTS_ADMIN],

  // lookups
  LIST_LOOKUPS: [SUPER_ADMIN, DISTRICTS_ADMIN],
  ADD_LOOKUPS: [SUPER_ADMIN, DISTRICTS_ADMIN],
  EDIT_LOOKUPS: [SUPER_ADMIN, DISTRICTS_ADMIN],

  // documents
  LIST_DOCUMENTS: [SUPER_ADMIN, DISTRICTS_ADMIN],
  ADD_DOCUMENTS: [SUPER_ADMIN, DISTRICTS_ADMIN],
  EDIT_DOCUMENTS: [SUPER_ADMIN, DISTRICTS_ADMIN],

  // messages
  LIST_MESSAGES: [SUPER_ADMIN, DISTRICTS_ADMIN],
  ADD_MESSAGES: [SUPER_ADMIN, DISTRICTS_ADMIN],
  EDIT_MESSAGES: [SUPER_ADMIN, DISTRICTS_ADMIN],

  // student forms
  LIST_STUDENT_FORMS: [
    SUPER_ADMIN,
    DISTRICTS_ADMIN,
    SCHOOLS_ADMIN,
    SCHOOLS_GENERAL,
  ],
  ADD_STUDENT_FORMS: [
    SUPER_ADMIN,
    DISTRICTS_ADMIN,
    SCHOOLS_ADMIN,
    SCHOOLS_GENERAL,
  ],
  EDIT_STUDENT_FORMS: [
    SUPER_ADMIN,
    DISTRICTS_ADMIN,
    SCHOOLS_ADMIN,
    SCHOOLS_GENERAL,
  ],

  // district forms
  LIST_DISTRICT_FORMS: [SUPER_ADMIN, DISTRICTS_ADMIN],
  EDIT_DISTRICT_FORMS: [SUPER_ADMIN, DISTRICTS_ADMIN],

  // imports
  ADD_IMPORTS: [SUPER_ADMIN, DISTRICTS_ADMIN],

  // reports
  VIEW_SCHOOL_REPORTS: [
    SUPER_ADMIN,
    DISTRICTS_ADMIN,
    SCHOOLS_ADMIN,
    SCHOOLS_GENERAL,
  ],
  VIEW_DISTRICT_REPORTS: [SUPER_ADMIN, DISTRICTS_ADMIN],
  VIEW_SUPER_REPORTS: [SUPER_ADMIN],
};

export default RBAC;
