// react
import React, { useState, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'

// Contexts
import AuthContext from '../../../utils/authProvider'

// ant design
import { Card, Button, Tag, Tooltip, Row, Col, Input, Table } from 'antd'

// operations
import { QUERY_SCHOOLS } from '../../../operations/queries/schools'
import { useLazyQuery } from '@apollo/client'

const SchoolList = () => {
  const Auth = useContext(AuthContext)
  const isSchoolsGeneral = Auth.hasRole('SCHOOLS_GENERAL')
  const isSuperAdmin = Auth.hasRole('SUPER_ADMIN')
  const [criterias, setCriterias] = useState({})
  const [pagination, setPagination] = useState({ offset: 0, limit: 10 });
  const [getSchoolData, { loading, data, error }] = useLazyQuery(QUERY_SCHOOLS, {
    variables: { ...criterias, ...pagination },
    fetchPolicy: 'cache-first',
    enabled: false,
    staleTime: Infinity
  });
  const [dataSource, setDataSource] = useState(data?.schools);
  useEffect(async () => {
    await getSchoolData('schools')
    setDataSource(data?.schools)
  }, [data]);
  const [value, setValue] = useState('');
  const getName = record =>
    isSchoolsGeneral ? record.name : record.nameGeneral
  const getDistrictName = record =>
    record.district?.name ? record.district.name : record.districtId
  const canAddSchools = Auth.hasPermission('ADD_SCHOOLS')
  const extra = (
    <>
      {canAddSchools && (
        <Tooltip title='Add new school'>
          <Link to='../school/add'>
            <Button type='primary'>Add School</Button>
          </Link>
        </Tooltip>
      )}
    </>
  )


  const reset = () => {
    setDataSource(data.schools)
    setValue('')
  }
  const canEditSchools = Auth.hasPermission('EDIT_SCHOOLS')
  const columns = [
    {
      title: 'School code',
      dataIndex: 'schoolCode',
      sorter: (a, b) => a.schoolCode.localeCompare(b.schoolCode),
      render: (value, record) => (
        <div>
          <div>
            {canEditSchools && record.isEditable ? (
              <Link to={`../school/${record.id}`}>{value}</Link>
            ) : (
              <>{value}</>
            )}
            &nbsp;
            {isSuperAdmin && record.hidden && <Tag color='orange'>Hidden</Tag>}
          </div>
          {isSuperAdmin && <div className='cellWithId'>{record.id}</div>}
          {!record.isEditable && <Tag color='geekblue'>Imported</Tag>}
        </div>
      )
    },
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (value, record) => <>{getName(record)} </>
    },
    {
      title: 'District',
      dataIndex: 'districtName',
      sorter: (a, b) => getDistrictName(a).localeCompare(getDistrictName(b)),
      render: (value, record) => <>{getDistrictName(record)} </>
    }
  ]

  return (
    <>

      <>
        <div className='page-container'>
          <Card title='Schools' bordered={false} extra={extra}>
            <Row justify={'space-left'} style={{ marginBottom: '1rem' }}>
              <Col span={6}>
                <Input
                  placeholder="Search by school name, code, or disctrict name"
                  value={value}
                  spellCheck={false}
                  onChange={e => {
                    let arr = []
                    const currValue = e.target.value;
                    setValue(currValue);
                    data?.schools?.filter(school => {
                      const schoolName = school.name ? school.name.toLowerCase().trim() : '';
                      const schoolCode = school.schoolCode ? school.schoolCode.toString().toLowerCase().trim() : '';
                      const districtName = school.district.name ? school.district.name.toString().toLowerCase().trim() : '';;
                      if (schoolName.includes(currValue)) {
                        arr.push(school)
                      }
                      else if (districtName.includes(currValue)) {
                        arr.push(school)
                      }
                      else if (schoolCode.includes(currValue)) {
                        arr.push(school)
                      }
                    });
                    setDataSource(arr);
                  }}
                />

              </Col>
              <Col>
                <Button
                  type='default'
                  style={{ marginLeft: '1rem' }}
                  onClick={reset}
                >
                  Reset
                </Button>
              </Col>
            </Row>
            <Table
              columns={columns}
              dataKey='students'
              dataSource={dataSource}
              loading={loading}
              //onChange={(pagination,filters,sorter,extra) => {console.log('Table onChange:',pagination,filters,sorter,extra)} }
              pagination={{
                showSizeChanger: true, //If excluded, antd turns this on automatically if total > 50
                // showQuickJumper: true,
                showTotal: (data) => `${data.toLocaleString("en")} Schools`,
                position: ["bottomRight"],
                defaultPageSize: 20,
              }}
            />
          </Card>
        </div>
      </>
    </>
  )
}

export default SchoolList
