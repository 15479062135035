import React from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import IssueModal from "../../components/IssueModal";
import Page from "../../components/Page";
import { Row, Col, Button, Card } from "antd";
const New = () => {


    const breadcrumb = [
        {
            path: "/",
            breadcrumbName: "Home",
        },
        {
            path: "/new",
            breadcrumbName: "new",
        },
        {
            path: "",
            breadcrumbName: "What's New",
        },
    ];
    const [issueModal, setIssueModal] = useState(false)

    return (
        <>
            <Page title="" breadcrumb={breadcrumb}>
                <Card title="10/28/2024" style={{ padding: '1rem' }}>
                    <div className="page-container">
                        <div className="page-padding-leftRight" style={{ paddingBottom: '.2rem' }} >
                            • Improved search across the entire site.
                        </div>
                        <div className="page-padding-leftRight" style={{ paddingBottom: '.2rem' }}>
                            • Admins can now add students to users' dashboards from the Edit User page.
                        </div>
                        <div className="page-padding-leftRight" style={{ paddingBottom: '.2rem' }}>
                            • Adjusted form validation requirements.
                        </div>
                        <div className="page-padding-leftRight" style={{ paddingBottom: '.2rem' }}>
                            • Removed Grades tab. If you need to add or change a grade, please contact TAMES.
                        </div>
                        <div className="page-padding-leftRight" style={{ paddingBottom: '.2rem' }}>
                            • Added "Both" option for individual and group therapy to student health plan form.
                        </div>
                        <div className="page-padding-leftRight" style={{ paddingBottom: '.2rem' }}>
                            • Simplified dashboard language to be consistent with health plans.
                        </div>
                        <div className="page-padding-leftRight" style={{ paddingBottom: '.2rem' }}>
                            • Added the ability to add plans/meetings to a student from the students page or their student profile page.
                        </div>
                        <div className="page-padding-leftRight" style={{ paddingBottom: '.2rem' }}>
                            • Fixed a bug where re-evaluation consent date would not display on the dashboard.
                        </div>
                        <div className="page-padding-leftRight" style={{ paddingBottom: '.2rem' }}>
                            • Added the What's New page.
                        </div>
                        <Row>
                            <Col span={8}>

                            </Col>
                            <Col span={8}>
                                <div style={{ paddingBottom: '.2rem', textAlign: 'center' }}>
                                    We are always elated to get feedback, suggestions, and bug reports. Feel free to message us!
                                </div>
                                <div style={{ textAlign: 'center' }}>
                                    <Button

                                        type='link'
                                        onClick={() => setIssueModal(true)}
                                    >
                                        Send Feedback
                                    </Button>
                                </div>

                            </Col>
                            <Col span={8}>
                            </Col>
                        </Row>
                        {/* <div style={{ fontWeight: '999' }} className="page-padding-leftRight page-padding-topBottom">
                            Previous Updates:
                        </div>
                        <div className="page-padding-leftRight" style={{ paddingBottom: '.2rem' }}>
                            • Developed the entire site.
                        </div> */}
                    </div>
                </Card>

                <IssueModal visible={issueModal} setVisible={setIssueModal} />

            </Page >
        </>
    );
};

export default New;
