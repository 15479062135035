// react
import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { Link, useNavigate } from 'react-router-dom'

// apollo & operations
import { useQuery } from '@apollo/client'
import { useMutation } from '@apollo/client'
import { QUERY_STUDENT } from '../../../operations/queries/students'
import { INSERT_MEETING } from '../../../operations/mutations/meetings'
import { UPDATE_STUDENT_MEETING_GENERAL } from '../../../operations/mutations/meetings'
import { GET_STUDENT_CURRENT_IEP_MEETING_ID } from '../../../operations/queries/students'

// ant design
import { Skeleton, Card, Grid, Row, Col, Button, Space, Tooltip, Form, Modal, Select, DatePicker, Switch, Typography } from 'antd'
import { CaretRightOutlined, CaretDownOutlined, PlusOutlined } from '@ant-design/icons'

// moment
import moment from 'moment'

// our stuff
import AuthContext from '../../../utils/authProvider'
import LookupSelectGeneric from '../../elements/common/LookupSelectGeneric'
import FileUploadStudent from '../../elements/FileUploadStudent'

const { useBreakpoint } = Grid
const AddMeetingForm = ({
  student,
  visible,
  onCancel,
  validationProps
}) => {
  const history = useNavigate()

  const [form] = Form.useForm()
  const Auth = useContext(AuthContext)
  const [errorMessage, setErrorMessage] = useState()
  const [insertMeeting, { loading: insertMeetingLoading }] =
    useMutation(INSERT_MEETING)
  const [
    updateStudentMeetingGeneral,
    { loading: updateStudentMeetingGeneralLoading }
  ] = useMutation(UPDATE_STUDENT_MEETING_GENERAL)
  const { loading, error, data } = useQuery(
    GET_STUDENT_CURRENT_IEP_MEETING_ID,
    {
      variables: { id: student?.student?.id }
    }
  )
  const { Text } = Typography
  const afterCreated = meetingId => {
    visible = false
    history('/student-form/' + meetingId)
  }

  return (
    <Modal
      visible={visible}
      title='New Meeting/Plan'
      okText='Add'
      confirmLoading={
        insertMeetingLoading && updateStudentMeetingGeneralLoading
      }
      cancelText='Cancel'
      onCancel={onCancel}
      onOk={() => {
        setErrorMessage()
        form
          .validateFields()
          .then(async values => {
            form.resetFields()
            let allowed = true
            if (
              values.meetingType === '504 Plan' &&
              values.isActiveIep === true
            ) {
              allowed = false
              notificationError(
                '504 Plan meetings are not allowed to be part of the active IEP'
              )
            }
            if (allowed === true) {
              const response = await insertMeeting({
                variables: {
                  studentMeetingGeneralInput: {
                    schoolId: values.schoolId,
                    studentId: student?.student?.id,
                    studentFormIds: [],
                    meetingType: values.meetingType,
                    meetingDate: values.date.format('MM-DD-YYYY'),
                    isActiveIep: values.isActiveIep,
                    iepMeetingId: student?.student?.currentIepMeetingId,
                    parentNotifications: []
                  }
                }
              })
              afterCreated(response?.data?.insertStudentMeetingGeneral.id)

              student?.studentMeetingsGeneral?.map(async e => {
                //mutate all the meetings if the new meeting is an active iep and any existing meeting is active,
                // if no meeting type ignore (crash bandaid for old data)
                if (
                  e.meetingType != null &&
                  e.isActiveIep === true &&
                  values.isActiveIep === true
                ) {
                  await updateStudentMeetingGeneral({
                    variables: {
                      id: e.id,
                      schoolId: e.schoolId,
                      studentId: e.studentId,
                      studentFormIds: e.studentFormIds,
                      meetingType: e.meetingType,
                      meetingDate: e.meetingDate,
                      isActiveIep: false,
                      iepMeetingId: e.currentIepMeetingId,
                      parentNotifications: []
                    }
                  })
                }
              })
              Auth.fetchDesktopData()
            }
          })
          .catch(info => {
            console.log(info, 'error')
            setErrorMessage('An error occured while adding the meeting.')
          })
      }}
    >
      <Form
        form={form}
        layout='vertical'
        name='addMeetingForm'
        initialValues={{
          schoolId:
            student?.student?.schools?.length === 1 ? student?.student?.schools[0]?.id : undefined
        }}
      >
        <Form.Item
          label='School'
          name='schoolId'
          rules={[
            {
              required: true,
              message: 'Please select a school'
            }
          ]}
        >
          <Select>
            {student?.student?.schools &&
              student?.student?.schools?.map(school => (
                <Select.Option key={school.id} value={school.id}>
                  {school.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>

        <LookupSelectGeneric
          singleMode={true}
          name='meetingType'
          type='Meeting Type'
          placeholder='Please select a meeting/plan type'
        />
        <Form.Item name='date' label='Meeting Date/Plan Start Date'>
          <DatePicker
            format={[
              'MM-DD-YYYY',
              'MM/DD/YYYY',
              'MM.DD.YY',
              'M-D-YYYY',
              'M/D/YYYY',
              'M.D.YYYY',
              'MM.DD.YYYY',
              'MM-DD-YY',
              'MM/DD/YY',
              'M-D-YY',
              'M/D/YY',
              'M.D.YY',
              'MMDDYYYY',
              'MMDDYY'
            ]}
          />
        </Form.Item>
        <Form.Item
          name='isActiveIep'
          label='Active'
          rules={[
            {
              required: true
            }
          ]}
          initialValue={true}
        >
          <Switch defaultChecked={true} />
        </Form.Item>
        {errorMessage && <Text type='danger'>{errorMessage}</Text>}
      </Form>
    </Modal>
  )
}

AddMeetingForm.propTypes = {
  student: PropTypes.object,
  visible: PropTypes.bool,
  afterCreated: PropTypes.func,
  onCancel: PropTypes.func
}

const StudentCard = ({ id, student }) => {
  const [addMeetingVisible, setAddMeetingVisible] = useState(false)
  const [addMeetingStudent, setAddMeetingStudent] = useState()
  const screens = useBreakpoint()
  const [collapse, setCollapse] = useState()
  const { loading, data } = useQuery(QUERY_STUDENT, {
    variables: { id, includeGoals: false },
    skip: !id
  })
  useEffect(() => {
    // collapse by default on smaller screens
    collapse === undefined &&
      screens.lg !== undefined &&
      setCollapse(screens.lg)
  }, [screens])

  if (!id) {
    return <></>
  }
  const gridStyle = {
    // display: "flex",
    width: '100%',
    outerHeight: 'flex',
    textAlign: 'left'
  }
  const addMeeting = ({ id, student }) => {
    setAddMeetingStudent(student)
    setAddMeetingVisible(true)
  }
  return (
    <>
      {data && (
        <>
          <div style={{ display: 'flex' }}>
            <AddMeetingForm
              student={student}
              visible={addMeetingVisible}
              onCancel={() => {
                setAddMeetingVisible(false)
              }}
            />

            <Card style={gridStyle} title={<> Student info </>}>
              <Skeleton loading={loading} active>
                <Row gutter={16}>
                  <Col flex='100px'>First Name:</Col>
                  <Col flex='auto'>{data?.student.firstName}</Col>
                </Row>
                <Row gutter={16}>
                  <Col flex='100px'>Last Name:</Col>
                  <Col flex='auto'>{data?.student.lastName}</Col>
                </Row>
                <Row gutter={16}>
                  <Col flex='100px'>Grade:</Col>
                  <Col flex='auto'>{data?.student.grade}</Col>
                </Row>
                <Row gutter={16}>
                  <Col flex='100px'>Code:</Col>
                  <Col flex='auto'>{data?.student.studentCode}</Col>
                </Row>
                <Row gutter={16}>
                  <Col flex='100px'>School:</Col>
                  <Col flex='auto'>{data?.student.schools[0]?.name}</Col>
                </Row>
                <Row gutter={16}>
                  <Col flex='100px'>DOB:</Col>
                  <Col flex='auto'>
                    {moment(data?.student.dateOfBirth).format('MM-DD-YYYY')}
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col flex='100px'>Age:</Col>
                  <Col flex='auto'>{data?.student.age}</Col>
                </Row>
                <Row gutter={16}>
                  <Col flex='100px'>Phone:</Col>
                  <Col flex='auto'>{data?.student.phoneNumber}</Col>
                </Row>
                <Row gutter={16}>
                  <Col flex='100px'>Address:</Col>
                  <Col flex='auto'>{data?.student.address}</Col>
                </Row>
                <Row gutter={16}>
                  <Col flex='100px'>City:</Col>
                  <Col flex='auto'>{data?.student.city}</Col>
                </Row>
                <Row gutter={16}>
                  <Col flex='100px'>Zip:</Col>
                  <Col flex='auto'>{data?.student.zip}</Col>
                </Row>
                <Row gutter={16}>
                  <Col flex='100px'>State:</Col>
                  <Col flex='auto'>{data?.student.state}</Col>
                </Row>
                <Row gutter={16} type='flex' align='center'>
                  <Col flex='100px' style={{ paddingBottom: '10px' }}>
                    <Link to={`../../student/edit/${data?.student.id}`}>
                      <Button>Edit Student Info</Button>
                    </Link>
                  </Col>
                  <Col>
                    <FileUploadStudent studentData={data} />
                  </Col>
                  <Col>
                    <Tooltip title="Add a meeting or plan.">
                      <Button onClick={() => addMeeting(id)}>
                        <PlusOutlined />
                        Add
                        {/* {!lookupLoading && onlyHealthPlan === false ? <>Add a Meeting</> : <>Add a Plan</>} */}
                      </Button>
                    </Tooltip>
                  </Col>
                </Row>
              </Skeleton>
            </Card>
          </div>
        </>
      )}
    </>
  )
}

StudentCard.propTypes = {
  id: PropTypes.number.isRequired
}

export default StudentCard
