// react
import React, { useState, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'

// Contexts
import AuthContext from '../../../utils/authProvider'

// ant design
import { Card, Button, Tag, Tooltip, Row, Col, Input, Table } from 'antd'

// operations
import { QUERY_USERS } from '../../../operations/queries/users'
import { useLazyQuery } from '@apollo/client'
// components
import UserSearchForm from './UserSearchForm'
import Listing from '../../Listing'

const UserList = () => {
  const Auth = useContext(AuthContext)
  const isSuperAdmin = Auth.hasRole('SUPER_ADMIN')
  const [criterias, setCriterias] = useState({})
  const [pagination, setPagination] = useState({ offset: 0, limit: 10 });
  const [getUserData, { loading, data, error }] = useLazyQuery(QUERY_USERS, {
    variables: { ...criterias, ...pagination },
    fetchPolicy: 'cache-first',
    enabled: false,
    staleTime: Infinity
  });
  const [dataSource, setDataSource] = useState(data?.users);
  useEffect(async () => {
    await getUserData('users')
    setDataSource(data?.users)
  }, [data]);
  const [value, setValue] = useState('');

  const canAddUsers = Auth.hasPermission('ADD_USERS')
  const extra = (
    <>
      {canAddUsers && (
        <Tooltip title='Add new user'>
          <Link to='../user/add'>
            <Button type='primary'>Add User</Button>
          </Link>
        </Tooltip>
      )}
    </>
  )
  const reset = () => {
    setDataSource(data.users)
    setValue('')
  }
  const canEditUsers = Auth.hasPermission('EDIT_USERS')
  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: (a, b) => a.email.localeCompare(b.email),
      render: (value, record) => (
        <div>
          <div>
            {canEditUsers && record.isEditable ? (
              <Link to={`../user/${record.id}`}>{value}</Link>
            ) : (
              <>{value}</>
            )}
            &nbsp;
            {isSuperAdmin && record.hidden && <Tag color='orange'>Hidden</Tag>}
          </div>
          {isSuperAdmin && <div className='cellWithId'>{record.id}</div>}
          {!record.isEditable && <Tag color='geekblue'>Imported</Tag>}
        </div>
      )
    }
  ]

  return (
    <>

      <>

        <div className='page-container'>
          <Card title='Users' bordered={false} extra={extra}>
            <Row justify={'space-left'} style={{ marginBottom: '1rem' }}>
              <Col span={6}>
                <Input
                  placeholder="Search by email"
                  value={value}
                  spellCheck={false}
                  onChange={e => {
                    let arr = []
                    const currValue = e.target.value;
                    setValue(currValue);
                    data?.users?.filter(user => {
                      const userName = user.email ? user.email.toLowerCase().trim() : '';
                      if (userName.includes(currValue)) {
                        arr.push(user)
                      }
                    });
                    setDataSource(arr);
                  }}
                />

              </Col>
              <Col>
                <Button
                  type='default'
                  style={{ marginLeft: '1rem' }}
                  onClick={reset}
                >
                  Reset
                </Button>
              </Col>
            </Row>
            <Table
              columns={columns}
              dataKey='users'
              dataSource={dataSource}
              loading={loading}
              //onChange={(pagination,filters,sorter,extra) => {console.log('Table onChange:',pagination,filters,sorter,extra)} }
              pagination={{
                showSizeChanger: true, //If excluded, antd turns this on automatically if total > 50
                // showQuickJumper: true,
                showTotal: (data) => `${data.toLocaleString("en")} Users`,
                position: ["bottomRight"],
                defaultPageSize: 20,
              }}
            />
          </Card>
        </div>
      </>

    </>
  )
}

export default UserList
