// React
import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
// Contexts and Providers
import { ApolloContext } from './apolloProvider'

// graphql operations
import { SIGN_IN, SIGN_OUT } from '../operations/mutations/authentication'
import { QUERY_ME } from '../operations/queries/userInfo'
import { QUERY_DESKTOP } from '../operations/queries/userInfo'
import { UPDATE_ME } from '../operations/mutations/users'

// other libraries
import { ApplicationError } from './applicationError'
import { notificationWarn } from './notification'

// import role base access control
import RBAC from './rbac'

import moment from 'moment'

// session expiry value in seconds
let sessionExpiry

// Create Auth context
const AuthContext = React.createContext()

/**
 * AuthProvider
 */
function AuthProvider(props) {
  const apollo = useContext(ApolloContext)
  const history = useNavigate()
  const { refreshToken } = apollo.getTokens()
  const [loading, setLoading] = useState(true)
  const [signedIn, setSignedIn] = useState()
  const [roles, setRoles] = useState({})
  const [districtsAdmin, setDistrictsAdmin] = useState([])
  const [schoolsAdmin, setSchoolsAdmin] = useState([])
  const [schoolsGeneral, setSchoolsGeneral] = useState([])
  const [userId, setUserId] = useState('')
  const [currentDistrictId, setCurrentDistrictId] = useState()
  const [currentSchoolId, setCurrentSchoolId] = useState()
  const [desktopData, setDesktopData] = useState(null)
  const signIn = async (email, password) => {
    setLoading(true)
    try {
      const response = await apollo.client.mutate({
        mutation: SIGN_IN,
        variables: { email, password }
      })
      apollo.setTokens(
        response.data.signIn.accessToken,
        response.data.signIn.refreshToken
      )
      apollo.updateSessionExpiry()
      await fetchUser() // wait for the fetch user to complete before continuing
      setSignedIn(true)
      await fetchDesktopData()
    } catch (e) {
      setLoading(false)
      if (
        !window.location.pathname.includes('security') &&
        !window.location.pathname.includes('invite') &&
        !window.location.pathname.includes('signin') &&
        !window.location.pathnam.includes('home')
      ) {
        // console.log('check for invite link happened whitespace for git')
        history('/')
      }
      throw new ApplicationError('Error signing in', e)
    }
    setLoading(false)
  }

  const signOut = async skipMutation => {
    console.log('signing out....')
    console.log(window.location.pathname)
    try {
      if (!skipMutation) {
        await apollo.client.mutate({ mutation: SIGN_OUT })
      }
      apollo.clearTokens()
      setSignedIn(false)
      setCurrentDistrictId()
      setCurrentSchoolId()
      if (
        !window.location.pathname.includes('security') &&
        !window.location.pathname.includes('invite') &&
        !window.location.pathname.includes('signin') &&
        !window.location.pathname.includes('home')
      ) {
        history('/')
      }
    } catch (e) {
      if (
        !window.location.pathname.includes('security') &&
        !window.location.pathname.includes('invite') &&
        !window.location.pathname.includes('signin') &&
        !window.location.pathname.includes('home')
      ) {
        history('/')
      }
      throw new ApplicationError('Error signing out', e)
    }
  }

  const resetPassword = async email => {
    try {
      console.log('@todo')
    } catch (e) {
      throw new ApplicationError('Error resetting password', e)
    }
  }
  const fetchDesktopData = async () => {
    try {
      const response = await apollo.client.query({
        query: QUERY_DESKTOP,
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first'
      })
      setDesktopData(response?.data?.me?.desktopStudents)
    } catch (e) {
      console.log(e, 'failed to grab desktop data')
    }
  }
  const fetchUser = async () => {
    //console.log('in fetchUser')
    try {
      const response = await apollo.client.query({
        query: QUERY_ME,
        fetchPolicy: 'no-cache'
      })
      //console.log(response)
      const roles = response?.data?.me?.permissions || {}
      const user = response?.data?.me
      setDistrictsAdmin(response?.data?.me.userAdminDistricts || [])
      setSchoolsAdmin(response?.data?.me.userAdminSchools || [])
      setSchoolsGeneral(response?.data?.me.userGeneralSchools || [])
      const hasRoles = Object.values(roles).find(el => el === true) // needs at least one permission
      if (response?.data?.me) {
        setCurrentDistrictId(response.data.me.currentDistrictId)
        setCurrentSchoolId(response.data.me.currentSchoolId)
      }
      if (hasRoles) {
        setRoles(roles)
        setUserId(user.id)
      } else {
        signOut()
        notificationWarn(
          'No roles found',
          "You account doesn't have any roles. Please contact your administrator."
        )
        if (
          !window.location.pathname.includes('security') &&
          !window.location.pathname.includes('invite') &&
          !window.location.pathname.includes('signin') &&
          !window.location.pathname.includes('home')
        ) {
          // console.log('check for invite link happened whitespace for git')
          history('/')
        }
      }
    } catch (e) {
      console.log(e, 'what is the error in fetch user')
      // if (
      //   !window.location.pathname.includes('security') &&
      //   !window.location.pathname.includes('invite') &&
      //   !window.location.pathname.includes('signin') &&
      //   !window.location.pathname.includes('home')
      // ) {
      //   // console.log('check for invite link happened whitespace for git')
      //   history('/')
      // }
      throw new ApplicationError('Error retrieving signed in user details', e)
    }
  }

  const hasRole = role => {
    const [_hasRole, setHasRole] = useState(false)

    useEffect(() => {
      setHasRole(roles[role] === true)
    }, [roles])

    return _hasRole
  }

  const hasPermission = permission => {
    const [hasPermission, setHasPermission] = useState(false)

    useEffect(() => {
      setHasPermission(
        RBAC[permission] &&
        Object.entries(roles).find(
          role => role[1] === true && RBAC[permission].includes(role[0])
        )
      )
    }, [roles])

    return hasPermission
  }

  // triggered once on mount

  useEffect(async () => {
    const refreshUser = async () => {
      try {
        //console.log('before fetchUser')
        await fetchUser()
        setSignedIn(true)
        setLoading(false)
      } catch (e) {
        console.log(e, 'i am error in auth')
        signOut(true)
        // if error and it isn't an invite link or reset link push the user home
        // if (
        //   !window.location.pathname.includes('security') &&
        //   !window.location.pathname.includes('invite') &&
        //   !window.location.pathname.includes('signin') &&
        //   !window.location.pathname === '/'
        // ) {
        //   console.log('check for invite link happened')
        //   history('/home')
        // }
        if (window.location.pathname === '/') {
          console.log('the thing happened')
        } else {
          notificationWarn('Disconnected', 'Your session has expired.')
        }
      }
    }
    // on app load fetch the user if already signed-in (refresh token exists) so that we get the roles and select a layout or display the selector
    // it triggers a token refresh request and re-authenticate the user
    refreshUser()

    if (refreshToken) {
      refreshUser()
    } else {
      setLoading(false)
    }

    // subscribes to the global tokenRefreshed event.
    // It is triggered when the access token expires and is refreshed, or when the app loads and a new access token is refreshed.
    // see apolloProvider.js for where the event is triggered
    window.addEventListener('tokenRefreshed', refreshUser)
  }, [])
  // add a week to this
  // handle the expiracy of the session. Returns the time left and signout when the time is out.
  const useSessionExpiry = () => {
    const [remaining, setRemaining] = useState('')
    useEffect(() => {
      const interval = setInterval(() => {
        const apolloSessionExpiry = apollo.getSessionExpiry()
        // import so that we skip when the value is not available yet
        if (apolloSessionExpiry !== undefined) {
          if (apolloSessionExpiry === -1) {
            sessionExpiry = 0
          } else if (apolloSessionExpiry > 0) {
            // we take the value and set 0. Avoiding concurent access.
            // add a week because this is annoying and doesn't really do anything for security
            sessionExpiry = apollo.getSessionExpiry() + 604800
            apollo.clearSessionExpiry()
          }

          sessionExpiry = sessionExpiry - 1
          if (sessionExpiry >= 1) {
            setRemaining(
              moment('1900-01-01 00:00:00')
                .add(sessionExpiry, 'seconds')
                .format('mm:ss')
            )
          } else {
            setRemaining('00:00')
            console.log('expiry happened')
            notificationWarn('Disconnected', 'Your session has expired.')
            signOut(true) // skip the signout mutation to avoid useless errors.
            if (
              !window.location.pathname.includes('security') &&
              !window.location.pathname.includes('invite') &&
              !window.location.pathname.includes('signin') &&
              !window.location.pathname === '/'
            ) {
              // console.log('check for invite link happened whitespace for git')
              history('/home')
            }
          }
        }
      }, 1000)
      return () => clearInterval(interval)
    }, [])
    return remaining
  }

  const updateCurrentDistrictId = async id => {
    const district = districtsAdmin.find(district => district.id === id)
    if (district) {
      setCurrentDistrictId(district)
      try {
        await apollo.client.mutate({
          mutation: UPDATE_ME,
          variables: { updateMeInput: { currentDistrictId: id } }
        })
      } catch (e) {
        notificationWarn(
          'Default district',
          "Couldn't update your default loaded district."
        )
      }
    }
    return district
  }

  const updateCurrentSchoolId = async id => {
    let school = schoolsAdmin.find(school => school.id === id)
    if (!school) {
      school = schoolsGeneral.find(school => school.id === id)
    }
    if (school) {
      setCurrentSchoolId(school)
      try {
        await apollo.client.mutate({
          mutation: UPDATE_ME,
          variables: { updateMeInput: { currentSchoolId: id } }
        })
      } catch (e) {
        notificationWarn(
          'Default school',
          "Couldn't update your default loaded school."
        )
      }
    }
    return school
  }

  return (
    <AuthContext.Provider
      value={{
        signIn,
        signOut,
        resetPassword,
        signedIn,
        useSessionExpiry,
        hasRole,
        hasPermission,
        schoolsAdmin,
        districtsAdmin,
        schoolsGeneral,
        userId,
        loading,
        currentDistrictId,
        currentSchoolId,
        updateCurrentDistrictId,
        updateCurrentSchoolId,
        desktopData,
        fetchDesktopData
      }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  items: PropTypes?.element
}

export default AuthContext

export { AuthContext, AuthProvider }
