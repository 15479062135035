import { gql } from "@apollo/client";

export const QUERY_USERS = gql`
  query ($searchCriteria: String) {
    users(searchCriteria: $searchCriteria) {
      id
      email
      roleIds
      subRoleId
      userAdminDistrictIds
      userAdminSchoolIds
      userGeneralSchoolIds
      hidden
      isEditable
    }
  }
`;

export const QUERY_USER = gql`
  query User($id: ID!) {
    user(id: $id) {
      id
      email
      roleIds
      subRoleId
      userAdminDistrictIds
      userAdminSchoolIds
      userGeneralSchoolIds
      desktopStudentIds
      coUsers
      allowedSchoolIds
      isSuperAdmin
      isDistrictsAdmin
      isSchoolsAdmin
      isSchoolsGeneral
      hidden
      createdBy
      createdAtAgo
      updatedAtAgo
      createdAt(format: "ddd mmm dd yyyy h:MM:ss TT Z")
      updatedAt(format: "ddd mmm dd yyyy h:MM:ss TT Z")
    }
  }
`;
