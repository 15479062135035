// react
import { React, useContext } from 'react'
import { Route, Routes, Redirect } from 'react-router-dom'
// import Route from "./Route";
// import Home from '../tames-template/src/pages/Home'
// routes
import Desktop from '../pages/Desktop'
import Iep from '../pages/Iep'

// login
// login page includes app version basically pointless
import Login from '../pages/public/Login'
import ForgotPassword from '../pages/public/ForgotPassword'
import SetPasswordLink from '../pages/public/SetPassword'
import LoginForm from './sections/login/LoginForm'
import About from '../pages/public/Home/About'

// user
import Users from '../pages/user/Users'
import User from '../pages/user/User'
import UserDelete from '../pages/user/UserDelete'

// district
import Districts from '../pages/district/Districts'
import District from '../pages/district/District'
import DistrictDelete from '../pages/district/DistrictDelete'

// school
import Schools from '../pages/school/Schools'
import School from '../pages/school/School'
import SchoolDelete from '../pages/school/SchoolDelete'

// role
import Roles from '../pages/role/Roles'
import Role from '../pages/role/Role'
import RoleDelete from '../pages/role/RoleDelete'

// student
import Students from '../pages/student/Students'
import Student from '../pages/student/Student'
import StudentDelete from '../pages/student/StudentDelete'
import StudentProfile from '../pages/student/StudentProfile'

// grade
import Grades from '../pages/grade/Grades'
import Grade from '../pages/grade/Grade'
import GradeDelete from '../pages/grade/GradeDelete'

// document
import Documents from '../pages/document/Documents'
import Document from '../pages/document/Document'
import DocumentDelete from '../pages/document/DocumentDelete'

// messages
import Messages from '../pages/message/Messages'
import Message from '../pages/message/Message'
import MessageDelete from '../pages/message/MessageDelete'

// student form
import StudentForm from '../pages/studentForm/StudentForm'

// district forms
import DistrictForms from '../pages/districtForm/DistrictForms'
import DistrictForm from '../pages/districtForm/DistrictForm'

// imports
import Import from '../pages/import/Import'

// reports
import MeetingsReport from '../pages/report/MeetingsReport'
import DistrictReport from '../pages/report/DistrictReport'
import SchoolReport from '../pages/report/SchoolReport'
import DistrictSummaryReport from '../pages/report/DistrictSummaryReport'
import DistrictUsageReport from '../pages/report/DistrictUsageReport'
import IStarReport from '../pages/report/IStarReport'
import CngReports from '../pages/report/CngReports'

// report builder reports
import SchoolReports from '../pages/report/SchoolReports'
// new
import New from '../pages/whatsnew/whatsnew'
// contexts
import AuthContext from '../utils/authProvider'
import Lookups from '../pages/lookup/Lookups'
import Lookup from '../pages/lookup/Lookup'

import { LoadingOutlined } from '@ant-design/icons'
import { Typography, Col, Row } from 'antd'

function Router() {
  const Auth = useContext(AuthContext)
  const { signedIn, loading } = useContext(AuthContext)
  const { Title } = Typography

  const Fallback = () => {
    return (
      <>
        <Row justify={'center'}>
          <Col>
            <Typography>
              Oops, this page doesn't exist.
            </Typography>
          </Col>
        </Row>

      </>
    )
  }
  const canListDistricts = Auth.hasPermission('LIST_DISTRICTS')
  const canAddDistricts = Auth.hasPermission('ADD_DISTRICTS')
  const canEditDistricts = Auth.hasPermission('EDIT_DISTRICTS')
  const canDeleteDistricts = Auth.hasPermission('DELETE_DISTRICTS')
  const canListSchools = Auth.hasPermission('LIST_SCHOOLS')
  const canAddSchools = Auth.hasPermission('ADD_SCHOOLS')
  const canEditSchools = Auth.hasPermission('EDIT_SCHOOLS')
  const canDeleteSchools = Auth.hasPermission('DELETE_SCHOOLS')
  const canListRoles = Auth.hasPermission('LIST_ROLES')
  const canAddRoles = Auth.hasPermission('ADD_ROLES')
  const canEditRoles = Auth.hasPermission('EDIT_ROLES')
  const canDeleteRoles = Auth.hasPermission('DELETE_ROLES')
  const canListStudents = Auth.hasPermission('LIST_STUDENTS')
  const canAddStudents = Auth.hasPermission('ADD_STUDENTS')
  const canEditStudents = Auth.hasPermission('EDIT_STUDENTS')
  const canDeleteStudentes = Auth.hasPermission('DELETE_STUDENTS')
  const canListGrade = Auth.hasPermission('LIST_GRADES')
  const canAddGrades = Auth.hasPermission('ADD_GRADES')
  const canEditGrades = Auth.hasPermission('EDIT_GRADES')
  const canDeleteGrades = Auth.hasPermission('DELETE_GRADES')
  const canListLookups = Auth.hasPermission('LIST_LOOKUPS')
  const canAddLookups = Auth.hasPermission('ADD_LOOKUPS')
  const canEditLookups = Auth.hasPermission('EDIT_LOOKUPS')
  const canListDocuments = Auth.hasPermission('LIST_DOCUMENTS')
  const canAddDocuments = Auth.hasPermission('ADD_DOCUMENTS')
  const canEditDocuments = Auth.hasPermission('EDIT_DOCUMENTS')
  const canDeleteDocuments = Auth.hasPermission('DELETE_DOCUMENTS')
  const canListMessages = Auth.hasPermission('LIST_MESSAGES')
  const canAddMessages = Auth.hasPermission('ADD_MESSAGES')
  const canEditMessages = Auth.hasPermission('EDIT_MESSAGES')
  const canDeleteMessages = Auth.hasPermission('DELETE_MESSAGES')
  const canListUsers = Auth.hasPermission('LIST_USERS')
  const canAddUsers = Auth.hasPermission('ADD_USERS')
  const canEditUsers = Auth.hasPermission('EDIT_USERS')
  const canDeleteUsers = Auth.hasPermission('DELETE_USERS')
  const canListStudentForms = Auth.hasPermission('LIST_STUDENT_FORMS')
  const canEditStudentForms = Auth.hasPermission('EDIT_STUDENT_FORMS')
  const canListDistrictForms = Auth.hasPermission('LIST_DISTRICT_FORMS')
  const canEditDistrictForms = Auth.hasPermission('EDIT_DISTRICT_FORMS')
  const canAddImports = Auth.hasPermission('ADD_IMPORTS')
  const canViewDistrictReports = Auth.hasPermission('VIEW_DISTRICT_REPORTS')
  const canViewSchoolReports = Auth.hasPermission('VIEW_SCHOOL_REPORTS')
  const canViewSuperReports = Auth.hasPermission('VIEW_SUPER_REPORTS')

  return (
    <>
      <>
        {signedIn ? (
          <Routes>
            <Route path="*" element={<Fallback />} />
            <Route path='/' element={<Desktop />} exact />
            <Route path='/iep/:id' element={<Iep />} />
            <Route path='/new' element={<New />} />
            {canListDistricts ? (
              <Route path='/districts' element={<Districts />} />
            ) : (
              <Route path='/districts' element={<Fallback />} />
            )}
            {canAddDistricts ? (
              <Route path='/district/add' element={<District />} exact />
            ) : (
              <Route path='/district/add' element={<Fallback />} />
            )}
            {canEditDistricts ? (
              <Route path='/district/:id' element={<District />} />
            ) : (
              <Route path='/district/:id' element={<Fallback />} />
            )}
            {canDeleteDistricts ? (
              <Route path='/district/delete/:id' element={<DistrictDelete />} />
            ) : (
              <Route path='/district/delete/:id' element={<Fallback />} />
            )}

            {canListSchools ? (
              <Route path='/schools' element={<Schools />} />
            ) : (
              <Route path='/schools' element={<Fallback />} />
            )}
            {canAddSchools ? (
              <Route path='/school/add' element={<School />} exact />
            ) : (
              <Route path='/school/add' element={<Fallback />} exact />
            )}
            {canEditSchools ? (
              <Route path='/school/:id' element={<School />} />
            ) : (
              <Route path='/school/:id' element={<Fallback />} />
            )}
            {canDeleteSchools ? (
              <Route path='/school/delete/:id' element={<SchoolDelete />} />
            ) : (
              <Route path='/school/delete/:id' element={<Fallback />} />
            )}

            {canListRoles ? (
              <Route path='/roles' element={<Roles />} />
            ) : (
              <Route path='/roles' element={<Fallback />} />
            )}
            {canAddRoles ? (
              <Route path='/role/add' element={<Role />} exact />
            ) : (
              <Route path='/role/add' element={<Fallback />} exact />
            )}
            {canEditRoles ? (
              <Route path='/role/:id' element={<Role />} />
            ) : (
              <Route path='/role/:id' element={<Fallback />} />
            )}
            {canDeleteRoles ? (
              <Route path='/role/delete/:id' element={<RoleDelete />} />
            ) : (
              <Route path='/role/delete/:id' element={<Fallback />} />
            )}

            {canListStudents ? (
              <Route path='/students' element={<Students />} />
            ) : (
              <Route path='/students' element={<Fallback />} />
            )}
            {canAddStudents ? (
              <Route path='/student/add' element={<Student />} exact />
            ) : (
              <Route path='/student/add' element={<Fallback />} exact />
            )}
            {canEditStudents ? (
              <Route path='/student/edit/:id' element={<Student />} />
            ) : (
              <Route path='/student/edit/:id' element={<Fallback />} />
            )}
            {canDeleteStudentes ? (
              <Route path='/student/delete/:id' element={<StudentDelete />} />
            ) : (
              <Route path='/student/delete/:id' element={<Fallback />} />
            )}
            {canEditStudents ? (
              <Route path='/student/:id' element={<StudentProfile />} />
            ) : (
              <Route path='/student/:id' element={<Fallback />} />
            )}

            {canListGrade ? (
              <Route path='/grades' element={<Grades />} />
            ) : (
              <Route path='/grades' element={<Fallback />} />
            )}
            {canAddGrades ? (
              <Route path='/grade/add' element={<Grade />} exact />
            ) : (
              <Route path='/grade/add' element={<Fallback />} exact />
            )}
            {canEditGrades ? (
              <Route path='/grade/:id' element={<Grade />} />
            ) : (
              <Route path='/grade/:id' element={<Fallback />} />
            )}
            {canDeleteGrades ? (
              <Route path='/grade/delete/:id' element={<GradeDelete />} />
            ) : (
              <Route path='/grade/delete/:id' element={<Fallback />} />
            )}

            {canListLookups ? (
              <Route path='/lookups' element={<Lookups />} />
            ) : (
              <Route path='/lookups' element={<Fallback />} />
            )}
            {canAddLookups ? (
              <Route path='/lookup/add' element={<Lookup />} exact />
            ) : (
              <Route path='/lookup/add' element={<Fallback />} exact />
            )}
            {canEditLookups ? (
              <Route path='/lookup/:id' element={<Lookup />} />
            ) : (
              <Route path='/lookup/:id' element={<Fallback />} />
            )}

            {canListDocuments ? (
              <Route path='/documents' element={<Documents />} />
            ) : (
              <Route path='/documents' element={<Fallback />} />
            )}
            {canAddDocuments ? (
              <Route path='/document/add' element={<Document />} exact />
            ) : (
              <Route path='/document/add' element={<Fallback />} exact />
            )}
            {canEditDocuments ? (
              <Route path='/document/:id' element={<Document />} />
            ) : (
              <Route path='/document/:id' element={<Fallback />} />
            )}
            {canDeleteDocuments ? (
              <Route path='/document/delete/:id' element={<DocumentDelete />} />
            ) : (
              <Route path='/document/delete/:id' element={<Fallback />} />
            )}

            {canListMessages ? (
              <Route path='/messages' element={<Messages />} />
            ) : (
              <Route path='/messages' element={<Fallback />} />
            )}
            {canAddMessages ? (
              <Route path='/message/add' element={<Message />} exact />
            ) : (
              <Route path='/message/add' element={<Fallback />} exact />
            )}
            {canEditMessages ? (
              <Route path='/message/:id' element={<Message />} />
            ) : (
              <Route path='/message/:id' element={<Fallback />} />
            )}
            {canDeleteMessages ? (
              <Route path='/message/delete/:id' element={<MessageDelete />} />
            ) : (
              <Route path='/message/delete/:id' element={<Fallback />} />
            )}

            {canListUsers ? (
              <Route path='/users' element={<Users />} />
            ) : (
              <Route path='/users' element={<Fallback />} />
            )}
            {canAddUsers ? (
              <Route path='/user/add' element={<User />} exact />
            ) : (
              <Route path='/user/add' element={<Fallback />} exact />
            )}
            {canEditUsers ? (
              <Route path='/user/:id' element={<User />} exact />
            ) : (
              <Route path='/user/:id' element={<Fallback />} exact />
            )}
            {canDeleteUsers ? (
              <Route path='/user/delete/:id' element={<UserDelete />} />
            ) : (
              <Route path='/user/delete/:id' element={<Fallback />} />
            )}

            {canListStudentForms ? (
              <Route
                path='/student-form/:studentMeetingId'
                element={<StudentForm />}
              />
            ) : (
              <Route
                path='/student-form/:studentMeetingId'
                element={<Fallback />}
              />
            )}
            {canEditStudentForms ? (
              <Route
                path='/student-form/:studentMeetingId'
                element={<StudentForm />}
                exact
              />
            ) : (
              <Route
                path='/student-form/:studentMeetingId'
                element={<Fallback />}
                exact
              />
            )}

            {canListDistrictForms ? (
              <Route path='/districtforms' element={<DistrictForms />} />
            ) : (
              <Route path='/districtforms' element={<Fallback />} />
            )}
            {canEditDistrictForms ? (
              <Route
                path='/districtforms/districtform/:id'
                element={<DistrictForm />}
                exact
              />
            ) : (
              <Route
                path='/districtforms/districtform/:id'
                element={<Fallback />}
                exact
              />
            )}

            {canAddImports ? (
              <Route path='/imports/add' element={<Import />} exact />
            ) : (
              <Route path='/imports/add' element={<Fallback />} exact />
            )}

            {canViewDistrictReports ? (
              <Route
                path='/report/meetingsreport'
                element={<MeetingsReport />}
                exact
              />
            ) : (
              <Route
                path='/report/meetingsreport'
                element={<Fallback />}
                exact
              />
            )}
            {canViewDistrictReports ? (
              <Route
                path='/report/districtreport'
                element={<DistrictReport />}
                exact
              />
            ) : (
              <Route
                path='/report/districtreport'
                element={<Fallback />}
                exact
              />
            )}
            {canViewDistrictReports ? (
              <Route
                path='/report/istarreport'
                element={<IStarReport />}
                exact
              />
            ) : (
              <Route path='/report/istarreport' element={<Fallback />} exact />
            )}
            {canViewSchoolReports ? (
              <Route
                path={'/report/schoolreport'}
                element={<SchoolReport />}
                exact
              />
            ) : (
              <Route path='/report/schoolreport' element={<Fallback />} exact />
            )}
            {canViewSuperReports ? (
              <Route
                path='/report/distrcitsummaryreport'
                element={<DistrictSummaryReport />}
                exact
              />
            ) : (
              <Route
                path='/report/distrcitsummaryreport'
                element={<Fallback />}
                exact
              />
            )}
            {canViewSuperReports ? (
              <Route
                path='/report/districtusagereport'
                element={<DistrictUsageReport />}
                exact
              />
            ) : (
              <Route
                path='/report/districtusagereport'
                element={<Fallback />}
                exact
              />
            )}

            {canViewSchoolReports ? (
              <Route
                path={'/report/schoolreports'} //If we don't want districtReportId in url then use this.  Otherwise use next line and change SideMenu.
                //path={'/report/schoolreports/:districtReportId'}
                element={<SchoolReports />}
                exact
              />
            ) : (
              <Route
                path='/report/schoolreports'
                element={<Fallback />}
                exact
              />
            )}

            <Route
              path='/security/:token'
              element={<SetPasswordLink mode='reset' />}
              exact
            />
            <Route path='/forgotpassword' element={<ForgotPassword />} exact />
            <Route
              path='/invite/:token'
              exact
              element={<SetPasswordLink mode='invite' />}
            />
            <Route path='/cngreports' element={<CngReports />} />
          </Routes>
        ) : (
          <>
            {loading ? (
              <>
                {' '}
                <div className='loading'>
                  <LoadingOutlined style={{ fontSize: '32px' }} />
                </div>
              </>
            ) : (
              <>
                <Routes>
                  <Route path='/cngreports' element={<CngReports />} />
                  <Route
                    path='/forgotpassword'
                    element={<ForgotPassword />}
                    exact
                  />
                  <Route path='/signIn' element={<LoginForm />} />
                  <Route path='/' element={<Login />} />
                  <Route path='/home' element={<Login />} />

                  <Route path='/about' element={<About />} />
                  <Route
                    path='/security/:token'
                    element={<SetPasswordLink />}
                    exact
                  />
                  <Route
                    path='/forgotpassword'
                    element={<ForgotPassword />}
                    exact
                  />
                  <Route
                    path='/invite/:token'
                    exact
                    element={<SetPasswordLink mode='invite' />}
                  />
                </Routes>
              </>
            )}
          </>
        )}
      </>
    </>
  )
}

// Note: the last route <Route path='/' element={<Login} /> is a fallback to the login page when the user tries to access a protected page and is not signed in.
export default Router
