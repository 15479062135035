// react
import React, { useState, useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'

// contexts
import AuthContext from '../../../utils/authProvider'

// ant design
import { Card, Button, Tag, Tooltip, Row, Col, Input, Table } from 'antd'

// operations
import { QUERY_DISTRICTS } from '../../../operations/queries/districts'
import { useLazyQuery } from '@apollo/client'
const DistrictList = () => {
  const Auth = useContext(AuthContext)
  const isSuperAdmin = Auth.hasRole('SUPER_ADMIN')
  const canEdit = Auth.hasPermission('EDIT_DISTRICTS')
  const canAdd = Auth.hasPermission('ADD_DISTRICTS')
  const isSchoolsGeneral = Auth.hasRole('SCHOOLS_GENERAL')
  const [criterias, setCriterias] = useState({})
  const [value, setValue] = useState('');
  const [pagination, setPagination] = useState({ offset: 0, limit: 10 });
  const [getDistrictData, { loading, data, error }] = useLazyQuery(QUERY_DISTRICTS, {
    variables: { ...criterias, ...pagination },
    fetchPolicy: 'cache-first',
    enabled: false,
    staleTime: Infinity
  });
  const [dataSource, setDataSource] = useState(data?.districts);
  useEffect(async () => {
    await getDistrictData('districts')
    setDataSource(data?.districts)
  }, [data]);
  const reset = () => {
    setDataSource(data.districts)
    setValue('')
  }
  const extra = (
    <>
      {canAdd && (
        <Tooltip title='Add new district'>
          <Link to='../district/add'>
            <Button type='primary'>Add District</Button>
          </Link>
        </Tooltip>
      )}
    </>
  )



  const getName = record =>
    isSchoolsGeneral ? record.name : record.nameGeneral
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (value, record) => (
        <div>
          <div>
            {canEdit ? (
              <Link to={`../district/${record.id}`}>{getName(record)}</Link>
            ) : (
              <>{getName(record)}</>
            )}
            &nbsp;
            {isSuperAdmin && record.hidden && <Tag color='orange'>hidden</Tag>}
          </div>
          {isSuperAdmin && <div className='cellWithId'>{record.id}</div>}
        </div>
      )
    }
  ]

  return (
    <>

      <div className='page-container'>
        <Card title='Districts' bordered={false} extra={extra}>
          <Row justify={'space-left'} style={{ marginBottom: '1rem' }}>
            <Col span={6}>
              <Input
                placeholder="Search by name"
                value={value}
                spellCheck={false}
                onChange={e => {
                  let arr = []
                  const currValue = e.target.value;
                  setValue(currValue);
                  data?.districts?.filter(district => {
                    const districtName = district.name ? district.name.toLowerCase().trim() : '';
                    if (districtName.includes(currValue)) {
                      arr.push(district)
                    }
                  });
                  setDataSource(arr);
                }}
              />

            </Col>
            <Col>
              <Button
                type='default'
                style={{ marginLeft: '1rem' }}
                onClick={reset}
              >
                Reset
              </Button>
            </Col>
          </Row>
          <Table
            columns={columns}
            dataKey='districts'
            dataSource={dataSource}
            loading={loading}
            //onChange={(pagination,filters,sorter,extra) => {console.log('Table onChange:',pagination,filters,sorter,extra)} }
            pagination={{
              showSizeChanger: true, //If excluded, antd turns this on automatically if total > 50
              // showQuickJumper: true,
              showTotal: (data) => `${data.toLocaleString("en")} Users`,
              position: ["bottomRight"],
              defaultPageSize: 20,
            }}
          />
        </Card>
      </div>
    </>
  )
}

export default DistrictList
